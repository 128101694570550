<template>
  <base-card class="pb-1 pt-4" style="border-radius: 1em">
    <v-row dense align="center" class="text-center">
      <v-col cols="12">
        <user-icon :userId="participant.id" clickable />
      </v-col>
      <v-col cols="12" class="text-center">
        <v-label class="text-button" style="font-size: 1em !important">{{
          participant.name
        }}</v-label>
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import BaseCard from '../BaseCard.vue';
import UserIcon from "../shared/UserIcon.vue";
export default {
  components: { UserIcon, BaseCard },
  name: "participant-list-item",
  props: {
    participant: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>