<template>
  <base-view :loading="isLoading" :error="error">
    <tournament-progress-banner />
    <base-section
      title="Teilnehmer"
      style="margin-top: 0.5em !important"
    >
      <v-row>
        <v-col>
          <participant-list />
        </v-col>
      </v-row>
    </base-section>
    <base-section
      title="Sportarten"
    >
      <v-row>
        <v-col>
          <sports-overview />
        </v-col>
      </v-row>
    </base-section>
    <base-section
      title="Turnierplan"
      initiallyOpened
    >
      <v-row>
        <v-col>
          <tournament-timeline />
        </v-col>
      </v-row>
    </base-section>
  </base-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseSection from "../components/BaseSection.vue";
import BaseView from "../components/BaseView.vue";
import ParticipantList from "../components/participants/ParticipantList.vue";
import TournamentProgressBanner from "../components/shared/TournamentProgressBanner.vue";
import SportsOverview from '../components/tournament/SportsOverview.vue';
import TournamentTimeline from '../components/tournament/TournamentTimeline.vue';
export default {
  components: {
    BaseView,
    BaseSection,
    ParticipantList,
    TournamentProgressBanner,
    TournamentTimeline,
    SportsOverview,
  },
  name: "tournament-view",
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters("activities", ["recentActivities"]),
    ...mapGetters("participants", ["currentTeam"]),
  },
  methods: {
    ...mapActions("activities", ["loadActivities"]),
    ...mapActions("sports", ["loadSports"]),
    ...mapActions("participants", ["loadParticipants", "loadTeams"]),
    ...mapActions("tournament", ["loadWeeks", "loadTournament", "loadDays"]),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.loadParticipants({ forceReload: false });
      await this.loadTournament({ forceReload: false });
      await this.loadWeeks({ forceReload: false });
      await this.loadDays({ forceReload: false });
      await this.loadTeams({ forceReload: false });
      await this.loadActivities({ forceReload: false });
      await this.loadSports({ forceReload: false, unauthenticated: true });
    } catch (ex) {
      this.error = ex.message;
      console.error(ex)
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style>
</style>