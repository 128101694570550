<template>
  <span :style="`cursor: ${clickable ? 'pointer' : 'default'}`">
    <v-avatar
      @click="showingUserInfo = clickable ? true : false"
      :size="size"
      :color="imageToDisplay ? 'white' : colorByName"
      :style="`font-size: ${(1.5 * parseInt(size)) / 50}em; color: white`"
      :class="currentWinnerTeam ? 'leader' : null"
      ><img
        v-if="imageToDisplay"
        :src="imageToDisplay ?? ''"
        style="width: 100%; height: auto; min-height: 100%"
      /><span v-else-if="participant">{{
        participant.name[0].toUpperCase()
      }}</span>
      <span v-else
        ><v-icon color="white" :size="size / 1.5">mdi-account</v-icon></span
      ></v-avatar
    >
    <base-dialog
      style="margin: 0 !important; padding: 0 !important"
      :open="showingUserInfo"
      @close="showingUserInfo = false"
    >
      <participant-overview class="pa-0 ma-0" :participant="participant" />
    </base-dialog>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import BaseDialog from "../BaseDialog.vue";
import ParticipantOverview from "../participants/ParticipantOverview.vue";
export default {
  components: { BaseDialog, ParticipantOverview },
  name: "user-icon",
  props: {
    clickable: {
      type: Boolean,
      default: false,
      required: false,
    },
    userId: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: "50",
    },
    image: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showingUserInfo: false,
    };
  },
  computed: {
    ...mapGetters("participants", ["participantById", "teamById"]),
    ...mapGetters("tournament", ["currentWeek"]),
    participant() {
      return this.participantById(this.userId);
    },
    currentWinnerTeam() {
      if (!this.participant || !this.currentWeek?.teams) {
        return false;
      }

      const teamId =
        this.currentWeek?.teams?.find((t) =>
          this.teamById(t)?.members?.includes(this.participant.id)
        ) ?? null;
      if (!teamId) return false;
      const team = this.teamById(teamId);

      return team?.weekRanking == 1 && team?.totalActivityPointsEarned != 0;
    },
    imageToDisplay() {
      if (this.image) return this.image;
      return this.participant?.image ?? null;
    },
    colorByName() {
      if (!this.participant) {
        return "primary";
      }
      const inputString = this.participant.name;
      let hash = 0;

      for (let i = 0; i < inputString.length; i++) {
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
      }

      hash = Math.abs(hash);

      function mapValue(value, min, max) {
        return Math.floor((value % (max - min + 1)) + min);
      }

      const r = mapValue(hash, 50, 200);
      const g = mapValue(hash + 1000, 50, 200);
      const b = mapValue(hash + 2000, 50, 200);

      const toHex = (value) => value.toString(16).padStart(2, "0");
      const color = "#" + toHex(r) + toHex(g) + toHex(b);

      return color;
    },
  },
};
</script>

<style>
.leader {
  box-shadow: 0 0 0.4em gold;
  animation: glow 2s ease 0s infinite;
  border: 1px solid gold !important;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0.3em gold;
  }
  50% {
    box-shadow: 0 0 0.3em yellow;
  }
  100% {
    box-shadow: 0 0 0.3em gold;
  }
}
</style>