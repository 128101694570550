<template>
  <v-container class="ma-0 pa-0">
    <v-row align="center" class="px-5 mt-2">
      <v-col class="text-right" cols="4">
        <user-icon :userId="currentParticipant.id" size="60" />
      </v-col>
      <v-col class="text-center"><v-icon>mdi-arrow-right</v-icon></v-col>
      <v-col class="text-left">
        <user-icon :image="selectedByteCode" size="60" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-file-input
          background-color="background"
          v-model="selectedPicture"
          class="file-input"
          prepend-icon="mdi-camera"
          show-size
          solo
          accept="image/png, image/jpeg, image/bmp"
          label="Profilbild auswählen"
          truncate-length="25"
          @change="onFileSelected"
        />
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col class="text-center">
        <v-btn :disabled="isLoading" @click="savePicture" color="primary"
          ><v-progress-circular indeterminate v-if="isLoading" /><span v-else
            >Speichern</span
          ></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: { UserIcon: () => import("../shared/UserIcon.vue") },
  name: "profile-picture-select",
  emits: ["close"],
  data() {
    return {
      selectedPicture: null,
      selectedByteCode: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("participants", ["currentParticipant"]),
  },
  methods: {
    ...mapActions("participants", ["updateParticipant"]),
    ...mapActions(["activateSuccessMessage"]),
    async onFileSelected() {
      if (!this.selectedPicture) {
        this.selectedByteCode = null;
        return;
      }
      try {
        const bytecode = await this.calculateBytecode();
        this.selectedByteCode = bytecode;
      } catch (error) {
        console.error(error);
      }
    },
    calculateBytecode() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        var img = new Image();

        reader.onload = (e) => {
          img.onload = () => {
            var canvas = document.createElement("canvas");

            var canvasSize = 180;
            var aspectRatio = img.width / img.height;

            canvas.width = canvasSize;
            canvas.height = canvasSize;

            var offsetX, offsetY, width, height;

            if (aspectRatio > 1) {
              offsetX = (img.width - img.height) / 2;
              offsetY = 0;
              width = img.height;
              height = img.height;
            } else {
              offsetX = 0;
              offsetY = (img.height - img.width) / 2;
              width = img.width;
              height = img.width;
            }
            var ctx = canvas.getContext("2d");

            ctx.drawImage(img, offsetX, offsetY, width, height, 0, 0, canvas.width, canvas.height);

            this.selectedByteCode = canvas.toDataURL("image/jpeg", 0.6);
          };

          img.src = e.target.result;
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(this.selectedPicture);
      });
    },
    async savePicture() {
      this.isLoading = true;
      try {
        await this.updateParticipant({
          id: this.currentParticipant.id,
          name: this.currentParticipant.name,
          image: this.selectedByteCode,
        });
        this.activateSuccessMessage({
          text: "Profilbild aktualisiert!",
          timeout: 3000,
        });
      } catch (ex) {
        console.error(ex);
      } finally {
        this.isLoading = false;
        this.$emit("close");
      }
    },
  },
};
</script>

<style>
.file-input * {
  cursor: pointer;
}
</style>