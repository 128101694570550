<template>
  <v-container>
    <v-row>
      <v-col
        class="mb-1"
        cols="6"
        v-for="participant in tournamentParticipants"
        :key="participant.id"
      >
        <participant-list-item :participant="participant" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ParticipantListItem from "./ParticipantListItem.vue";
export default {
  components: { ParticipantListItem },
  name: "participant-list",
  computed: {
    ...mapGetters("participants", ["participantById"]),
    ...mapGetters("tournament", ["tournament"]),
    tournamentParticipants() {
      return this.tournament.participants.map((p) => this.participantById(p));
    },
  },
};
</script>

<style>
</style>