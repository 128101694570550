<template>
  <base-view :loading="isLoading" :error="error">
    <week-progress-banner />
    <base-section initiallyOpened style="margin-top: 0.5em !important">
      <v-row no-gutters>
        <v-col cols="12" class="text-center">
          <v-label style="font-size: 1.5em !important" class="text-overline"
            >Dein Team</v-label
          >
        </v-col>
        <v-col class="text-center"
          ><v-label class="text-overline"> in dieser Woche:</v-label></v-col
        >
      </v-row>
      <v-row dense>
        <v-col>
          <team-participants-view :team="currentTeam" />
        </v-col>
      </v-row>
    </base-section>
    <base-section title="Wochen-Ranking" initiallyOpened>
      <template #header>
        <v-spacer />
        <v-col cols="2">
          <v-btn icon @click.stop="update" :disabled="isUpdating">
            <v-icon v-if="!isUpdating">mdi-reload</v-icon
            ><v-progress-circular indeterminate v-else /></v-btn></v-col
      ></template>
      <div :class="{ loading: isUpdating }">
        <v-row>
          <v-col>
            <team-ranking />
          </v-col>
        </v-row>
      </div>
    </base-section>
  </base-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseSection from "../components/BaseSection.vue";
import BaseView from "../components/BaseView.vue";
import WeekProgressBanner from "../components/shared/WeekProgressBanner.vue";
import TeamParticipantsView from "../components/teams/TeamParticipantsView.vue";
import TeamRanking from "../components/teams/TeamRanking.vue";
export default {
  components: {
    BaseView,
    BaseSection,
    WeekProgressBanner,
    TeamParticipantsView,
    TeamRanking,
  },
  name: "current-week-view",
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters("activities", ["recentActivities"]),
    ...mapGetters("participants", ["currentTeam"]),
  },
  methods: {
    ...mapActions("activities", ["loadActivities"]),
    ...mapActions("sports", ["loadSports"]),
    ...mapActions("participants", ["loadParticipants", "loadTeams"]),
    ...mapActions("tournament", ["loadWeeks", "loadTournament", "loadDays"]),
    async update() {
      try {
        this.isUpdating = true;
        await this.loadTeams({ forceReload: true });
        await this.loadParticipants({ forceReload: true });
      } catch (ex) {
        this.error = ex.message;
        console.error(ex);
      } finally {
        this.isUpdating = false;
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.loadParticipants({ forceReload: false });
      await this.loadTournament({ forceReload: false });
      await this.loadWeeks({ forceReload: false });
      await this.loadDays({ forceReload: false });
      await this.loadTeams({ forceReload: false });
      await this.loadActivities({ forceReload: false });
      await this.loadSports({ forceReload: false, unauthenticated: true });
    } catch (ex) {
      this.error = ex.message;
      console.error(ex);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style>
</style>