<template>
  <v-container>
    <v-row dense>
      <v-col
        v-for="participantId in team.members"
        class="text-center"
        :key="participantId"
        ><user-icon clickable :userId="participantId"
      /></v-col>
    </v-row>
    <v-row dense>
      <v-col
        v-for="participantId in team.members"
        class="text-center"
        :key="participantId"
        ><v-label>{{ participantById(participantId).name }}</v-label></v-col
      >
    </v-row>
    <v-row dense>
      <v-col
        v-for="participantId in team.members"
        class="text-center"
        :key="participantId"
        ><points-display :points="participantById(participantId).weekActivityPoints" /></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PointsDisplay from '../shared/PointsDisplay.vue';
import UserIcon from "../shared/UserIcon.vue";
export default {
  components: { UserIcon, PointsDisplay },
  name: "team-participants-view",
  props: {
    team: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters("participants", ["participantById"]),
  },
};
</script>

<style>
</style>