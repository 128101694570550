<template>
  <base-view class="mt-7 px-3">
    <participant-overview :participant="currentParticipant" />
  </base-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseView from '../components/BaseView.vue';
import ParticipantOverview from "../components/participants/ParticipantOverview.vue";
export default {
  components: { ParticipantOverview, BaseView },
  name: "profile-view",
  computed: {
    ...mapGetters("participants", ["currentParticipant"]),
  },
  methods: {
    ...mapActions("activities", ["loadActivities"]),
    ...mapActions("sports", ["loadSports"]),
    ...mapActions("participants", ["loadParticipants", "loadTeams"]),
    ...mapActions("tournament", ["loadWeeks", "loadTournament", "loadDays"]),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.loadParticipants({ forceReload: false });
      await this.loadTournament({ forceReload: false });
      await this.loadWeeks({ forceReload: false });
      await this.loadDays({ forceReload: false });
      await this.loadTeams({ forceReload: false });
      await this.loadActivities({ forceReload: false });
      await this.loadSports({ forceReload: false, unauthenticated: true });
    } catch (ex) {
      this.error = ex.message;
      console.error(ex)
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style>
</style>