<template>
  <div>
    <v-btn icon x-large :color="achievementColor" @click="dialogOpen = true"
      ><v-icon x-large
        >mdi-{{
          achievementLevel > 0 ? achievement.icon : "circle-small"
        }}</v-icon
      ></v-btn
    >
    <base-dialog
      :open="dialogOpen"
      :title="achievement.name"
      :subtitle="achievement.description"
      @close="dialogOpen = false"
    >
      <badge-details :achievement="achievement" :participant="participant" />
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from "../BaseDialog.vue";
import BadgeDetails from "./BadgeDetails.vue";
export default {
  components: { BaseDialog, BadgeDetails },
  name: "badge-view",
  data() {
    return {
      dialogOpen: false,
    };
  },
  props: {
    achievement: {
      type: Object,
      required: true,
    },
    participant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    achievementColor() {
      switch (this.achievementLevel) {
        case 1:
          return "#950";
        case 2:
          return "#bdc";
        case 3:
          return "#fc1";
        default:
          return "default";
      }
    },
    achievementLevel() {
      if (this.achievement.values.length == 1) {
        return this.achievement.value < this.achievement.values[0] ? 0 : 3;
      }
      if (this.achievement.value < this.achievement.values[0]) {
        return 0;
      }
      if (this.achievement.value < this.achievement.values[1]) {
        return 1;
      }
      if (this.achievement.value < this.achievement.values[2]) {
        return 2;
      }
      return 3;
    },
  },
};
</script>

<style>
</style>