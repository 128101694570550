var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.sports && _vm.sports.length > 0 && _vm.sports[0].badges)?_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-label',[_vm._v("Klicke auf das "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-outline")]),_vm._v(" um mehr über eine Sportart zu erfahren.")],1),_c('div',[_c('v-row',{staticClass:"mt-1",attrs:{"align":"center","justify":"center"}},_vm._l((_vm.sports),function(sport){return _c('v-col',{key:sport.id,staticClass:"text-center py-2 px-1",attrs:{"cols":"4"}},[_c('base-card',{class:{
            'pb-1': true,
            sportMaxed: sport.badges.maxed,
          },on:{"click":function($event){return _vm.sportClicked(sport)}}},[_c('div',[_c('v-icon',{class:{
                'mt-3': true,
                'mb-1': true,
                sportLeader: sport.badges.leader,
              }},[_vm._v("mdi-"+_vm._s(sport.icon))])],1),_c('v-label',{staticStyle:{"font-size":"0.85em"}},[_vm._v(_vm._s(sport.name))]),_c('v-btn',{staticClass:"info-button",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.sportToShowDetails = sport}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information-outline ")])],1)],1)],1)}),1)],1),(_vm.sportToShowDetails)?_c('base-dialog',{attrs:{"open":_vm.sportToShowDetails != null,"title":_vm.sportToShowDetails?.name ?? null,"subtitle":_vm.sportToShowDetails?.description
        ? `inkl. ${_vm.sportToShowDetails.description}`
        : null},on:{"close":function($event){_vm.sportToShowDetails = null}}},[_c('sport-details',{attrs:{"sport":_vm.sportToShowDetails}})],1):_vm._e()],1):_c('v-container',[_c('v-row',[_c('v-col',[_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","size":20}}),_vm._v("Statistik wird abgerufen...")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }