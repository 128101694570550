<template>
  <v-card
    v-if="clickable"
    @click="click"
    :color="color ?? 'cardBackground'"
    elevation="3"
    :height="fullHeight ? '90vh' : undefined"
  >
    <slot></slot>
  </v-card>
  <v-card v-else :color="color ?? 'cardBackground'" elevation="3">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  emits: ["click"],
  name: "base-card",
  props: {
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style>
</style>