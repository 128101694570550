<template>
  <div>
    <v-col>
      <v-label v-if="tournament.status == 'waiting'" style="font-size: 0.9em">
        Die Dauer des Turniers und die Verteilung der Teams ändern sich, wenn
        weitere Teilnehmer beitreten.
      </v-label>
    </v-col>
    <base-card>
      <v-card-title> Start </v-card-title>
      <v-card-subtitle>
        von {{ tournament.beginDate.toLocaleDateString("de-DE") }}
      </v-card-subtitle>
    </base-card>
    <v-timeline side="end" dense>
      <v-timeline-item
        :color="getColor(week)"
        :small="!getLarge(week)"
        v-for="week in weeksOfTournament"
        :key="week.id"
        fill-dot
        right
      >
        <week-view
          :week="week"
          :initiallyOpened="getLarge(week)"
        />
      </v-timeline-item>
    </v-timeline>
    <base-card>
      <v-card-title> Ende </v-card-title>
      <v-card-subtitle>bis {{ tournament.endDate.toLocaleDateString("de-DE") }}</v-card-subtitle>
    </base-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseCard from "../BaseCard.vue";
import WeekView from "./WeekView.vue";
export default {
  components: { WeekView, BaseCard },
  name: "tournament-timeline",
  computed: {
    ...mapGetters("tournament", ["weeks", "currentWeek", "tournament"]),
    weeksOfTournament() {
      if (!this.tournament) return []
      return this.weeks.filter(w => this.tournament.weeks.includes(w.id))
    }
  },
  methods: {
    getColor(week) {
      if (!this.currentWeek) return "primary";
      if (week.number < this.currentWeek.number) {
        return "secondary";
      }
      if (week.number >= this.currentWeek.number) {
        return "primary";
      }
    },
    getLarge(week) {
      if (!this.currentWeek) return false;
      return week.number == this.currentWeek.number;
    }
  },
};
</script>

<style>
</style>