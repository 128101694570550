<template>
  <div class="welcome">
    <v-container class="d-flex" fill-height>
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <div class="text-h5" id="welcome-label">Willkommen,</div>
          <div class="text-h2" id="name-label">{{ name }}</div>
          <div class="text-h5 mt-10" id="to-label">zur</div>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0 text-center sc-label">
          <span style="font-size: 4.5em">SPORT</span>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0 text-center sc-label">
          <span style="font-size: 2.45em; position: relative; bottom: 1em"
            >CHALLENGE</span
          >
        </v-col>
      </v-row>
    </v-container>
    <confetti-display :delay="3500" :amount="80" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ConfettiDisplay from "../shared/ConfettiDisplay.vue";
export default {
  components: { ConfettiDisplay },
  name: "welcome-user",
  props: {
    name: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions("client", ["authenticate"]),
  },
  created() {
    setTimeout(async () => {
      await this.authenticate({ name: this.name, password: this.password });
      this.$router.replace({ name: "tournament" }).catch(() => {});
    }, 13000);
  },
};
</script>

<style scoped>
.welcome {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  background-color: Canvas;
  z-index: 1000;
  animation: fadeIn ease 0.5s;
}

#welcome-label {
  animation: fadeIn ease 2s;
}

#name-label {
  animation: fadeIn ease 2.5s;
  text-shadow: 0 0 30px rgba(200, 200, 0, 0.5);
}

#to-label {
  animation: fadeIn ease 4s;
}

.sc-label {
  position: relative;
  animation: fadeIn ease 5.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div,
span {
  color: #000;
}
</style>