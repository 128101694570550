<template>
  <div>
    <base-card
      clickable
      :class="`pa-1 my-2 activity-card${rightToLeft ? '-rtl' : ''}`"
      @click="activity.activityImage != null ? loadAndViewImage() : changeLabels()"
    >
      <v-row v-if="!rightToLeft">
        <v-col cols="2">
          <user-icon clickable :userId="participant.id" />
        </v-col>
        <v-col class="text-left pl-4" cols="4"
          ><points-display :points="activity.points"
        /></v-col>
        <v-col cols="5"
          ><v-icon small class="mr-2">mdi-{{ sport.icon }}</v-icon
          ><v-label>{{ toHoursAndMinutesString(activity.time) }} h</v-label
          ><v-spacer /> {{ label }} </v-col
        >
        <v-icon small style="right: 0.5em">{{
          activity.activityImage != null ? "mdi-camera" : "mdi-camera-off-outline"
        }}</v-icon>
      </v-row>
      <v-row v-else>
        <v-icon small style="left: 1em" class="mr-3"
          >mdi-{{ activity.activityImage != null ? "camera" : "camera-off-outline" }}</v-icon
        >
        <v-col class="text-right mx-0 px-0" cols="4"
          ><v-icon small class="mr-2">mdi-{{ sport.icon }}</v-icon
          ><v-label>{{ toHoursAndMinutesString(activity.time) }} h</v-label
          ><v-spacer /> {{ label }} </v-col
        >
        <v-col class="text-right" cols="4"
          ><points-display class="pr-1" :points="activity.points"
        /></v-col>
        <v-col cols="2" class="text-right" style="left: 1em">
          <user-icon clickable :userId="participant.id" />
        </v-col>
      </v-row>
    </base-card>
    <image-viewer
      v-if="viewingImage"
      :opened="viewingImage"
      :source="activityImage?.bytecode"
      @close="viewingImage = false"
      :likes="activityImage?.likes"
      @like="likeImage"
      :ownerUser="participant"
    />
  </div>
</template>

<script>
import { toHoursAndMinutesString } from "@/helper/displayHelper";
import { mapActions, mapGetters } from "vuex";
import BaseCard from "../BaseCard.vue";
import PointsDisplay from "../shared/PointsDisplay.vue";
import UserIcon from "../shared/UserIcon.vue";
import ImageViewer from "../shared/ImageViewer.vue";
export default {
  components: { UserIcon, PointsDisplay, BaseCard, ImageViewer },
  name: "activity-view",
  props: {
    activity: {
      required: true,
      type: Object,
    },
    rightToLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      viewingImage: false,
      currentLabel: "sport"
    };
  },
  computed: {
    ...mapGetters("participants", ["participantById"]),
    ...mapGetters("sports", ["sportById"]),
    ...mapGetters("activities", ["activityImages"]),
    participant() {
      return this.participantById(this.activity.participant);
    },
    sport() {
      return this.sportById(this.activity.sport);
    },
    label() {
      if (this.currentLabel == "time") {
        let serverTime = new Date(this.activity.beginTime)
        return serverTime.toLocaleTimeString("de-DE", {hour: '2-digit', minute:'2-digit'}) + " Uhr"
      }
      return this.sport.name
    },
    activityImage() {
      const image = this.activityImages.find(
        (i) => i.activity == this.activity.id
      );
      return image ?? null;
    },
  },
  methods: {
    ...mapActions("activities", [
      "loadActivityImageForActivity",
      "likeActivityImage",
    ]),
    toHoursAndMinutesString(time) {
      return toHoursAndMinutesString(time);
    },
    loadAndViewImage() {
      this.viewingImage = true;
      this.loadActivityImageForActivity(this.activity);
    },
    async likeImage() {
      await this.likeActivityImage(this.activityImage);
    },
    changeLabels() {
      this.currentLabel = this.currentLabel == "sport" ? "time" : "sport";
    },
  },
};
</script>

<style>
.activity-card {
  border-top-left-radius: 1.8em !important;
  border-bottom-left-radius: 1.8em !important;
  width: 95%;
}

.activity-card-rtl {
  border-top-right-radius: 1.8em !important;
  border-bottom-right-radius: 1.8em !important;
  width: 95%;
  position: relative;
  left: 5%;
}
</style>