<template>
  <base-card id="day-progress-banner">
    <v-card-title class="pb-1">
      <v-col class="pa-0 ma-0" cols="8" style="font-size: .9em">{{ tournament.name }} </v-col
      ><v-spacer /><v-col class="pa-0 ma-0 text-right"
        ><v-btn icon @click="showingInfo = true"
          ><v-icon>mdi-information-outline</v-icon></v-btn
        ></v-col
      >
    </v-card-title>
    <v-card-text v-if="tournament.status != 'ended'">
      <v-col class="pa-0 ma-0"
        ><v-label id="time-left" class="mr-2">{{
          timeLeftString
        }}</v-label></v-col
      >
    </v-card-text>

    <div v-if="tournament.status == 'waiting'">
      <v-card color="warning" class="ma-2" v-if="!hasTeams"
        ><v-card-title
          ><v-icon class="mr-2">mdi-alert</v-icon> Du nimmst noch nicht teil
        </v-card-title>
        <v-card-subtitle
          >Die Anzahl der Teilnehmer ist momentan ungerade, daher bist du noch
          keinen Teams zugeordnet.</v-card-subtitle
        >
      </v-card>
      <v-card color="success" class="ma-2" v-else
        ><v-card-title
          ><v-icon class="mr-2">mdi-check</v-icon> Du nimmst teil!
        </v-card-title>
      </v-card>
    </div>

    <div v-if="tournament.status == 'ended'">
      <v-card color="info" class="ma-2"
        ><v-card-title
          ><v-icon class="mr-2">mdi-flag-checkered</v-icon> Das Turnier ist beendet
        </v-card-title>
        <v-card-subtitle
          >Danke für deine Teilnahme. Die Ergebnisse werden demnächst bekanntgegeben.</v-card-subtitle
        >
      </v-card>
    </div>

    <v-progress-linear
      height="5"
      :value="tournament.status == 'waiting' ? 0 : relativeTimePassed"
      stream
    >
    </v-progress-linear>
    <base-dialog :open="showingInfo" @close="showingInfo = false">
      <info-card />
    </base-dialog>
  </base-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BaseCard from '../BaseCard.vue';
import BaseDialog from "../BaseDialog.vue";
import InfoCard from "./InfoCard.vue";
export default {
  components: { InfoCard, BaseDialog, BaseCard },
  name: "tournament-progress-banner",
  created() {
    this.timeUntilNextDay =
      (new Date().setHours(24, 0, 0, 0) - Date.now() - this.serverDifference) /
      1000;
    window.setInterval(this.runTimer, 1000);
  },
  data() {
    return {
      timeUntilNextDay: null,
      showingInfo: false,
    };
  },
  computed: {
    ...mapGetters("tournament", ["tournament", "serverDifference"]),
    ...mapGetters("participants", ["teams"]),
    ...mapState("client", ["participantId"]),
    hasTeams() {
      return (
        this.teams.filter((t) => t.members.includes(this.participantId))
          .length != 0
      );
    },
    timeLeft() {
      if (this.tournament.status == "running") {
        return this.tournament.endDate - new Date();
      }
      return this.tournament.beginDate - new Date();
    },
    daysUntilEnd() {
      return Math.floor(this.timeLeft / 1000 / 60 / 60 / 24);
    },
    timeLeftString() {
      return this.daysUntilEnd > 1
        ? `${this.tournament.status == "running" ? "endet" : "beginnt"} in ${
            this.daysUntilEnd
          } Tagen`
        : `${this.tournament.status == "running" ? "endet" : "beginnt"} ${
            this.daysUntilEnd == 0 ? "heute" : "morgen"
          } Nacht`;
    },
    relativeTimePassed() {
      if (this.tournament.status == "running")
        return (
          ((this.tournament.endDate -
            this.tournament.beginDate -
            this.timeLeft) /
            (this.tournament.endDate - this.tournament.beginDate)) *
          100
        );
      return null;
    },
  },
  methods: {
    ...mapActions("tournament", ["loadCurrentDay"]),
    runTimer() {
      this.timeUntilNextDay -= 1;
      if (this.timeUntilNextDay <= 0) {
        window.parent.location.reload();
      }
    },
  },
};
</script>

<style>
#day-progress-banner {
  position: sticky;
  top: 3.5em;
  left: 0;
  width: 100vw !important;
  border-radius: 0;
  z-index: 100;
}
</style>