<template>
  <div>
    <activity-view
      v-for="activity in activities"
      :key="activity.id"
      :activity="activity"
      :rightToLeft="activity.rightToLeft"
    />
    <v-col class="text-center" v-if="activities.length == 0"
      ><v-label>{{ emptyString }}</v-label></v-col
    >
  </div>
</template>

<script>
import ActivityView from "./ActivityView.vue";
export default {
  components: { ActivityView },
  name: "activity-list",
  props: {
    activities: {
      type: Array,
      required: true,
    },
    emptyString: {
      type: String,
      required: false,
      default: "Keine Aktivitäten",
    },
  },
};
</script>

<style>
</style>