<template>
  <v-dialog :value="open" persistent @click:outside="close">
    <base-card :fullHeight="fullHeight" color="background">
      <v-card-title class="mb-1">{{ title }}</v-card-title>
      <v-card-subtitle v-if="subtitle">{{ subtitle }}</v-card-subtitle>
      <v-card-text>
        <slot v-if="!loading && !error"> </slot>
        <v-container v-else>
          <v-row justify="center" align="center">
            <v-col class="text-center">
              <div v-if="error">
                <v-row>
                  <v-col class="text-center"
                    ><v-label class="text-h5">Ups!</v-label></v-col
                  ></v-row
                >
                <v-row
                  ><v-col class="text-center"
                    ><v-label>{{ error }}</v-label></v-col
                  ></v-row
                >
              </div>
              <v-progress-circular v-else indeterminate :size="50" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-btn
        v-if="!loading"
        id="close-button"
        fab
        :elevation="0"
        class="mr-1 mt-1"
        color="transparent"
        @click="close"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </base-card>
  </v-dialog>
</template>

<script>
import BaseCard from "./BaseCard.vue";
export default {
  components: { BaseCard },
  name: "base-dialog",
  emits: ["close"],
  data() {
    return {};
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    close() {
      if (this.loading) return;
      this.$emit("close");
    },
  },
};
</script>

<style>
#close-button {
  position: absolute;
  right: 0;
  top: 0;
}
</style>