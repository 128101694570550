<template>
  <div>
    <v-row class="pa-0 ma-0 mb-6 mt-8">
      <v-col cols="12" class="pa-0 ma-0 text-center">
        <span style="font-size: 4.5em">SPORT</span>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0 text-center">
        <span style="font-size: 2.45em; position: relative; top: 0em"
          >CHALLENGE</span
        >
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col>
        <p>
          Bei der Sport Challenge geht es nicht nur um den Sieg, sondern vor
          allem darum, deinem Körper etwas Gutes zu tun.
        </p>
        <p>Hier findest du einige Infos und Regeln zur Sport Challenge.</p>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-left">
        <base-section title="Ablauf">
          <p>
            In diesem Turnier treten die Teilnehmer in Teams an. Jede Woche
            erhälst du einen neuen Teampartner - so lange, bis du einmal mit
            jedem und gegen jeden gespielt hast. Im Bereich
            <b><v-icon x-small class="mr-1">mdi-trophy</v-icon>Turnier</b>
            kannst du im <b>Turnierplan</b> die Zusammenstellung der Teams
            einsehen.
          </p>
          <p>
            Indem du Sport betreibst, sammelst du <b>Aktivitätspunkte</b> für
            dein Team. Am Ende jeder Woche erhalten die Teilnehmer deines Teams
            <b>Turnierpunkte</b>, die für den Gesamtsieg entscheidend sind.
          </p>
        </base-section>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-left">
        <base-section title="Wertung">
          <p>
            Du kannst an einem Tag pro Sportart 10 Punkte sammeln. Die
            Punkteverteilung für die Sportzeiten findest du im Bereich
            <b><v-icon x-small class="mr-1">mdi-trophy</v-icon>Turnier</b> unter
            <b>Sportarten</b>.
          </p>
          <p>
            Am Ende der Woche erhält dein Team Turnierpunkte abhängig von der
            Anzahl der gesammelten Aktivitätspunkte. Dabei erhält der letzte
            Platz 1 Punkt, der vorletzte Platz 2 Punkte, der drittletzte 3
            Punkte und so weiter.
          </p>
          <p>
            Im Bereich
            <b><v-icon x-small class="mr-1">mdi-calendar-week</v-icon>Woche</b>
            findest du die Rangliste aller Teams für die derzeitige Woche sowie
            die Leistungen deines Teams. Nach Ablauf einer Woche sind diese jeweiligen Ergebnisse nicht mehr einsehbar.
          </p>
        </base-section>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-left">
        <base-section title="Abzeichen und Statistik">
          <p>
            Im Bereich
            <b><v-icon x-small class="mr-1">mdi-account</v-icon>Profil</b> kannst
            du interessante Statistiken über deine Leistungen einsehen. Das
            umfasst zum Beispiel gesammelte Aktivitätspunkte oder Zeiten in einzelnen
            Sportarten.
          </p>
          <p>
            Unter <b>Abzeichen</b> findest du dort gesammelte Errungenschaften,
            die du in verschiedenen Kategorien erlangen kannst.
          </p>
          <p>
            In der Liste der Sportarten kannst du zudem zwei weitere Abzeichen für jede
            Sportart sammeln. Das <b>schillernde Abzeichen</b> erhälst du,
            sobald du eine Sportart an einem einzigen Tag ausgiebig betrieben
            und dort 10 Punkte gesammelt hast. Das
            <b>goldene Abzeichen</b> einer Sportart besitzt nur ein einziger
            Teilnehmer auf einmal, nämlich derjenige mit den meisten
            Aktivitätspunkten in diesem Sport. Du kannst es also auch wieder
            verlieren.
          </p>
        </base-section>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-left">
        <base-section title="Sport eintragen">
          <p>
            Immer wenn du Sport gemacht hast, solltest du deine Aktivität noch
            am selben Tag auf dieser Webseite eintragen, indem du unten rechts
            auf das
            <v-btn
              color="primary"
              class="mx-2"
              x-small
              fab
              elevation="0"
              readonly
              ><v-icon>mdi-plus</v-icon></v-btn
            >
            drückst.
          </p>
          <p>
            Nachdem du deine Zeiten angegeben hast, werden automatisch die
            Punkte ermittelt, die dir und deinem Team gutgeschrieben werden.
          </p>
          <p>
            Du kannst nur Aktivitäten für dich selbst und für den heutigen Tag
            eintragen.
          </p>
        </base-section>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="text-left">
        <base-section title="Sportarten">
          <p>
            Eine Übersicht über alle Sportarten, Infos und gesammelte Abzeichen
            findest du hier und im Bereich
            <b><v-icon x-small class="mr-1">mdi-trophy</v-icon>Turnier</b> unter
            <b>Sportarten</b>.
          </p>
          <p>
            <sports-overview />
          </p>
        </base-section>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseSection from "../BaseSection.vue";
import SportsOverview from "../tournament/SportsOverview.vue";
export default {
  components: { BaseSection, SportsOverview },
  name: "info-card",
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>