<template>
  <v-container class="pa-0 ma-0">
    <v-row dense justify="center" align="center">
      <v-col cols="4">
        <user-icon
          clickable
          :style="`position: relative; right: ${i * 0.4}em`"
          v-for="(participant, i) in participants"
          :key="participant.id"
          :userId="participant.id"
          size="35"
        />
      </v-col>
      <v-col class="text-left">
        <v-row>
          <v-col>
            <v-label>{{ points }} Pkt.</v-label></v-col
          ><v-col class="text-right">
            <v-label>+ {{ team.weekTournamentPoints }} <v-icon size="medium">mdi-trophy</v-icon></v-label></v-col
          >
        </v-row>
        <v-progress-linear :value="relativePoints" stream buffer-value="0">
        </v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserIcon from "../shared/UserIcon.vue";
export default {
  components: { UserIcon },
  name: "team-view",
  props: {
    team: {
      type: Object,
      required: true,
    },
    points: {
      type: Number,
      required: true,
      default: 0,
    },
    maxPoints: {
      type: Number,
      required: true,
      default: 100,
    },
  },
  computed: {
    ...mapGetters("participants", ["participantsByTeamId"]),
    participants() {
      return this.participantsByTeamId(this.team.id);
    },
    relativePoints() {
      return (this.points / this.maxPoints) * 100;
    },
  },
};
</script>

<style>
</style>