<template>
  <v-container class="pa-0 ma-0">
    <v-row>
      <v-col class="text-center">
        {{ dayString }} <points-display :points="teamAcitivtyPoints" />
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <team-participant-comparison
          :team="team"
          :teamActivities="teamActivitiesToday"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <activity-list :activities="teamActivitiesTodayWithDisplayInfo" emptyString="Dein Team hat heute noch keinen Sport gemacht :(" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ActivityList from "../activities/ActivityList.vue";
import PointsDisplay from "../shared/PointsDisplay.vue";
import TeamParticipantComparison from "./TeamParticipantComparison.vue";
export default {
  components: { TeamParticipantComparison, PointsDisplay, ActivityList },
  name: "team-day-activity",
  props: {
    team: {
      type: Object,
      required: true,
    },
    dayString: {
      type: String,
      required: false,
      default: "heute",
    },
  },
  computed: {
    ...mapGetters("activities", ["teamActivitiesToday"]),
    ...mapGetters("sports", ["sportById"]),
    ...mapGetters("client", ["participantId"]),
    teamAcitivtyPoints() {
      return this.teamActivitiesToday.reduce(
        (sum, a) => sum + a.points,
        0
      );
    },
    teamActivitiesTodayWithDisplayInfo() {
      const activities = [];
      for (const i in this.teamActivitiesToday) {
        activities[i] = {
          ...this.teamActivitiesToday[i],
          rightToLeft:
            this.teamActivitiesToday[i].participant != this.participantId,
        };
      }
      return activities;
    },
  },
};
</script>

<style>
</style>