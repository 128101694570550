<template>
  <v-container>
    <v-row>
      <v-col
        class="text-center"
        v-for="achievement in participant.achievements"
        :key="achievement.name"
        cols="4"
      >
        <badge-view :achievement="achievement" :participant="participant" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BadgeView from "./BadgeView.vue";
export default {
  components: { BadgeView },
  name: "badge-list",
  props: {
    participant: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style>
</style>