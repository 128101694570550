<template>
  <v-row align="center">
    <v-col class="text-right">
      <v-text-field
        ref="hoursInput"
        class="hour-input"
        v-model.number="selectedHours"
        label="Stunden"
        prepend-icon="mdi-minus"
        @click:prepend="decrease"
        placeholder="00"
        persistent-placeholder
        @change="setComputedTime"
        @blur="formatTimes"
        @click="selectedHours = null"
        max="2"
      />
    </v-col>
    :
    <v-col>
      <v-text-field
        ref="minutesInput"
        v-model.number="selectedMinutes"
        label="Minuten"
        append-outer-icon="mdi-plus"
        @click:append-outer="increase"
        placeholder="00"
        persistent-placeholder
        @change="setComputedTime"
        @blur="formatTimes"
        @click="selectedMinutes = null"
        max="2"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "activity-time-picker",
  emits: ["input"],
  props: {
    available: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      selectedHours: 0,
      selectedMinutes: null,
      computedTime: null,
      unit: 20,
    };
  },
  created() {
    this.computedTime = 0;
  },
  watch: {
    selectedMinutes: {
      handler(v) {
        if (v > 10) {
          this.$refs.minutesInput.blur()
        }
      }
    },
    selectedHours: {
      handler(v) {
        if (v > 0) {
          this.$refs.hoursInput.blur()
        }
      }
    },
    computedTime: {
      handler(v) {
        this.computedTime = Math.max(Math.min(this.available, v), 1);
        this.selectedMinutes = this.computedTime % 60;
        this.selectedHours = Math.floor(this.computedTime / 60);
        this.formatTimes();
        this.updateTime();
      },
      immediate: true,
    },
  },
  computed: {
    maxHours() {
      return Math.floor(this.available / 60);
    },
    maxMinutes() {
      return this.available - 60 * this.selectedHours < 60
        ? this.available - 60 * this.selectedHours
        : 59;
    },
    minHours() {
      return 0;
    },
    minMinutes() {
      return this.selectedHours > 0 ? 0 : 1;
    },
  },
  methods: {
    increase() {
      if (this.computedTime % this.unit != 0)
        this.computedTime -= this.computedTime % this.unit;
      this.computedTime += this.unit;
    },
    decrease() {
      if (this.computedTime % this.unit != 0)
        this.computedTime -= this.computedTime % this.unit;
      this.computedTime -= this.unit;
    },
    setComputedTime() {
      this.validate();
      this.computedTime = this.selectedMinutes + this.selectedHours * 60;
    },
    updateTime() {
      this.$emit("input", this.computedTime);
    },
    formatTimes() {
      this.selectedHours = this.selectedHours = String(
        this.selectedHours
      ).padStart(2, "0");
      this.selectedMinutes = this.selectedMinutes = String(
        this.selectedMinutes
      ).padStart(2, "0");
    },
    validate() {
      if (
        this.selectedHours > this.maxHours ||
        this.selectedHours < this.minHours ||
        this.selectedMinutes > this.minMinutes ||
        this.selectedMinutes > this.maxMinutes
      ) {
        document.activeElement.blur();
      }
      this.selectedHours = Math.min(
        Math.max(this.minHours, this.selectedHours),
        this.maxHours
      );
      this.selectedMinutes = Math.min(
        Math.max(this.minMinutes, this.selectedMinutes),
        this.maxMinutes
      );
    },
  },
};
</script>

<style>
.hour-input * {
  text-align: right;
}
</style>