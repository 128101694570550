import { convertIrisToIds, convertListIrisToIds, makeAuthenticatedRequest } from "@/helper/requestHelper"

const participantsModule = {
    state: {
        participants: [],
        teams: [],
        participantsLastFetched: null,
        teamsLastFetched: null
    },
    getters: {
        participants(state) {
            return state.participants
        },
        isNameAvailable(state) {
            return name => !state.participants.map(p => p.name).includes(name)
        },
        teams(state, _, rootState) {
            const teams = state.teams
            for (let team of teams) {
                const currentParticipantIndex = team.members.findIndex(v => v == rootState.client.participantId)
                if (currentParticipantIndex != -1) {
                    let tmp = team.members[0]
                    team.members[0] = team.members[currentParticipantIndex]
                    team.members[currentParticipantIndex] = tmp
                }
            }
            return teams
        },
        teamById(_, getters) {
            return teamId => getters.teams.find(t => t.id == teamId)
        },
        teamByParticipant(_, getters) {
            return participantId => getters.teams.find(t => t.members.includes(participantId))
        },
        currentParticipant(state, _, rootState) {
            if (!state.participants || !rootState.client.participantId) return null
            return state.participants.find(p => p.id == rootState.client.participantId)
        },
        currentTeam(_, getters) {
            if (!getters.teams || !getters.currentParticipant) return null
            return getters?.weekTeams?.find(t => t.members.includes(getters.currentParticipant.id)) ?? null
        },
        weekTeams(_, getters, _2, rootGetters) {
            return getters?.teams?.filter(t => t.week == rootGetters["tournament/currentWeek"].id) ?? []
        },
        participantById(state) {
            return id => state.participants.find(p => p.id == id)
        },
        participantByName(state) {
            return name => state.participants.find(p => p.name.toLowerCase() == name.toLowerCase())
        },
        participantsByTeamId(_, getters) {
            return (id) => {
                const team = getters.teams.find(t => t.id == id);
                const participants = [];
                for (const participantId of team.members) {
                    participants.push(getters.participantById(participantId))
                }
                return participants
            }
        },
        participantsNeedReload(state) {
            if (!state.participantsLastFetched) return true
            return Date.now() - state.participantsLastFetched > 60000
        },
        teamsNeedReload(state) {
            if (!state.teamsLastFetched) return true
            return Date.now() - state.teamsLastFetched > 60000
        }
    },
    mutations: {
        setParticipants(state, participants) {
            state.participants = participants
        },
        setParticipant(state, participant) {
            state.participants = state.participants.filter(p => p.id != participant.id)
            state.participants.push(participant)
        },
        setTeams(state, teams) {
            state.teams = teams
        },
        setParticipantsLastFetched(state) {
            state.participantsLastFetched = Date.now()
        },
        setTeamsLastFetched(state) {
            state.teamsLastFetched = Date.now()
        },
        clearLastFetched(state) {
            state.participantsLastFetched = null
            state.teamsLastFetched = null
        },
    },
    actions: {
        async loadParticipants({ commit, getters, rootGetters }, { forceReload }) {
            if (!getters.participantsNeedReload && !forceReload) return

            let data = null;
            if (rootGetters["client/isAuthenticated"]) {
                data = await makeAuthenticatedRequest("/participants.json",
                    { headers: { "Accept": "application/json" } })
            } else {
                const response = await fetch(process.env.VUE_APP_BACKEND_URL + "/participants.json",
                    { headers: { "Accept": "application/json" } })
                data = await response.json()
            }

            const participants = convertListIrisToIds(data)
            const participantsToSet = rootGetters["tournament/tournament"] ? participants.filter(p => p.tournament == rootGetters["tournament/tournament"].id) : participants
            commit("setParticipants", participantsToSet)
            commit("setParticipantsLastFetched")
        },
        async loadParticipant({ commit }, id) {
            const response = await fetch(process.env.VUE_APP_BACKEND_URL + `/participants/${id}.json`,
                { headers: { "Accept": "application/json" } })
            const data = await response.json()

            const participant = convertIrisToIds(data)
            commit("setParticipant", participant)
        },
        async loadTeams({ commit, getters }, { forceReload }) {
            if (!getters.teamsNeedReload && !forceReload) return

            const response = await makeAuthenticatedRequest("/teams.json?order[weekRanking]=asc")
            commit("setTeams", convertListIrisToIds(response))
            commit("setTeamsLastFetched")
        },
        async updateParticipant({ dispatch }, { id, name, image }) {
            await makeAuthenticatedRequest(`/participants/${id}.json`, { method: "PATCH", body: JSON.stringify({ name, image }), headers: { "Content-Type": "application/merge-patch+json" } })
            dispatch("loadParticipants", { forceReload: true })
        }
    },
    namespaced: true
}

export default participantsModule