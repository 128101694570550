import { makeAuthenticatedRequest } from "@/helper/requestHelper"

const sportsModule = {
    state: {
        sports: [
        ],
        lastFetched: null
    },
    getters: {
        sports(state) {
            return state.sports.sort((a, b) => a.name > b.name ? 1 : -1)
        },
        sportById(state) {
            return id => state.sports.find(s => s.id == id)
        },
        needsReload(state) {
            if (!state.lastFetched) return true
            return Date.now() - state.lastFetched > 300000
        }
    },
    mutations: {
        setSports(state, sports) {
            state.sports = sports.sort((a, b) => a.name > b.name ? 1 : -1)
        },
        setLastFetched(state) {
            state.lastFetched = Date.now()
        },
        clearLastFetched(state) {
            state.lastFetched = null
        },
    },
    actions: {
        async loadSports({ getters, commit }, options) {
            if (!getters.needsReload && !options.forceReload) return

            let sports = [];
            if (options.unauthenticated) {
                const response = await fetch(process.env.VUE_APP_BACKEND_URL + "/sports.json",
                    { headers: { "Accept": "application/json" } })
                sports = await response.json()
            } else {
                sports = await makeAuthenticatedRequest(`/sports.json`)
            }
            commit("setSports", sports)

            commit("setLastFetched")
        }
    },
    namespaced: true
}

export default sportsModule