<template>
  <base-card>
    <v-container>
      <v-row align="center">
        <v-col cols="4" class="text-right">
          <v-icon x-large class="mr-1" :color="iconColor">{{
            icon
          }}</v-icon></v-col
        ><v-col
          ><v-row no-gutters
            ><v-col cols="12"
              ><label class="text-button"
                >Streak: {{ participant.streak.current }}</label
              ></v-col
            ><v-col>
              <v-label
                class="text-uppercase"
                style="font-size: 0.8em; bottom: 0.6em"
                >Höchste: {{ participant.streak.highest }}</v-label
              ></v-col
            ></v-row
          ></v-col
        >
      </v-row></v-container
    ></base-card
  >
</template>

<script>
import { mapGetters } from "vuex";
import BaseCard from "../BaseCard.vue";
export default {
  components: { BaseCard },
  name: "streak-view",
  props: {
    participant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("tournament", ["serverDifference"]),
    ...mapGetters("activities", ["personalActivitiesToday"]),
    icon() {
      return (!this.activityDoneToday && this.timeUntilNextDay < 15000 && this.participant.streak.current > 0) ? "mdi-fire-alert" : "mdi-fire";
    },
    iconColor() {
      return this.activityDoneToday ? "#f60" : "gray";
    },
    activityDoneToday() {
      return this.personalActivitiesToday.length > 0;
    },
    timeUntilNextDay() {
      return (
        (new Date().setHours(24, 0, 0, 0) -
          Date.now() -
          this.serverDifference) /
        1000
      );
    },
  },
};
</script>

<style>
</style>
BaseCard