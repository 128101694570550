<template>
  <v-container v-if="!isAuthenticated"
    ><v-row class="pa-0 ma-0 mb-6">
      <v-col cols="12" class="pa-0 ma-0 text-center">
        <span style="font-size: 4.5em; position: relative; top: 0.55em"
          >SPORT</span
        >
      </v-col>
      <v-col cols="12" class="pa-0 ma-0 text-center">
        <span style="font-size: 2.45em">CHALLENGE</span>
      </v-col>
    </v-row>
    <div v-if="!isRegistering">
      <v-row class="mt-0 pt-0 mb-3">
        <v-col class="text-center mx-6">
          <login-form />
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col class="text-center mx-6">
          <v-label
            >Wenn du noch keinen Zugang hast, dann
            <router-link :to="{ name: 'register' }"
              >registriere dich</router-link
            >
            um mitzumachen!</v-label
          >
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row class="mt-0 pt-0 mb-3">
        <v-col class="text-center mx-6">
          <register-form />
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col class="text-center mx-6">
          <v-label
            >Wenn du bereits einen Zugang hast, dann
            <router-link :to="{ name: 'login' }">melde dich an</router-link
            >!</v-label
          >
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-container v-else class="d-flex" fill-height>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-progress-circular
          style="position: relative; top: 35vh"
          :size="50"
          indeterminate
      /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import LoginForm from "../components/login/LoginForm.vue";
import RegisterForm from "../components/login/RegisterForm.vue";
export default {
  components: { LoginForm, RegisterForm },
  name: "login-view",
  computed: {
    ...mapGetters("participants", ["participants"]),
    ...mapGetters("client", ["isAuthenticated"]),
    isRegistering() {
      return this.$route.name == "login" ? false : true;
    },
  },
};
</script>

<style>
</style>