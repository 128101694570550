<template>
  <v-menu
    @click:outside="menu = false"
    v-model="menu"
    style="z-index: 9999"
    :close-on-content-click="false"
    :close-on-click="true"
    :nudge-width="200"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn large icon v-on="on">
        <v-icon>mdi-palette-outline</v-icon>
      </v-btn>
    </template>
    <base-card>
      <v-card-subtitle>Farbschema</v-card-subtitle>
      <v-card-text>
        <v-btn
          icon
          x-large
          :disabled="selectionIsSelected(selection)"
          @click="setThemeSelection(selection)"
          hover
          v-for="(selection, index) in themeSelections"
          :key="index"
        >
          <v-icon :color="selection.preview">{{
            selectionIsSelected(selection) ? "mdi-check-circle" : "mdi-circle"
          }}</v-icon>
        </v-btn>
      </v-card-text>
    </base-card>
  </v-menu>
</template>

<script>
import BaseCard from "../BaseCard.vue";
export default {
  components: { BaseCard },
  name: "theme-changer",
  data() {
    return {
      menu: false,
      selectedTheme: "Standard",
      selectedDark: false,
      themes: [
        {
          name: "Standard",
          dark: {
            preview: "#222222",
            primary: "#1976D2",
            secondary: "#575757",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FFC107",
            background: "#222222",
            cardBackground: "#222222",
          },
          light: {
            preview: "#FFFFFF",
            primary: "#1976D2",
            secondary: "#C9C9C9",
            accent: "#82B1FF",
            error: "#FF5252",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FFC107",
            background: "#FFFFFF",
            cardBackground: "#F0F7FF",
          },
        },
        {
          name: "Pink",
          preview: "#FF4081",
          dark: {
            preview: "#AA004C",
            primary: "#FF4051",
            accent: "#21CFF3",
            secondary: "#FF4343",
            success: "#990099",
            info: "#F34FC6",
            warning: "#FB8C00",
            error: "#FF5252",
            background: "#220011",
            cardBackground: "#77002A",
          },
          light: {
            preview: "#FF90B0",
            primary: "#EF8B99",
            accent: "#22DAFF",
            secondary: "#FFABC4",
            success: "#4CAF50",
            info: "#FF53D0",
            warning: "#FF8E00",
            error: "#FF5252",
            background: "#FFDCF5",
            cardBackground: "#FFCBE4",
          },
        },
        {
          name: "Grün",
          dark: {
            preview: "#007E33",
            primary: "#22AE55",
            accent: "#4CAF50",
            secondary: "#22AE55",
            success: "#22AE55",
            info: "#22AE55",
            warning: "#FFD600",
            error: "#FF6D00",
            background: "#001A00",
            cardBackground: "#004D30",
          },
          light: {
            preview: "#B5F5B6",
            primary: "#00AA50",
            accent: "#64DD17",
            secondary: "#A7E588",
            success: "#B2FF59",
            info: "#00E5FF",
            warning: "#FFEA00",
            error: "#FF3D00",
            background: "#EFFCD9",
            cardBackground: "#D7F5B8",
          },
        },
        {
          name: "Blau",
          dark: {
            preview: "#0D47A1",
            primary: "#5080B0",
            accent: "#00BCD4",
            secondary: "#5080B0",
            success: "#4CAF50",
            info: "#03A9F4",
            warning: "#FF9800",
            error: "#F44336",
            background: "#00001A",
            cardBackground: "#003060",
          },
          light: {
            preview: "#C0E4FF",
            primary: "#51A4CA",
            accent: "#00E5FF",
            secondary: "#81D4FA",
            success: "#8BC34A",
            info: "#4FC3F7",
            warning: "#FFC107",
            error: "#FF5722",
            background: "#E1F5FE",
            cardBackground: "#D0F4FE",
          },
        },
        {
          name: "Experimental",
          dark: {
            preview: "#6D02DD",
            primary: "#9070C0",
            accent: "#EC407A",
            secondary: "#9070C0",
            success: "#7CB342",
            info: "#FF4081",
            warning: "#FFA726",
            error: "#E53935",
            background: "#222244",
            cardBackground: "#604090",
          },
          light: {
            preview: "#C395E5",
            primary: "#A67EB0",
            accent: "#E040FB",
            secondary: "#A67EB0",
            success: "#4CAF50",
            info: "#8F81E5",
            warning: "#FFC107",
            error: "#F44336",
            background: "#F3E5F5",
            cardBackground: "#E3C5E5",
          },
        },
      ],
    };
  },
  computed: {
    themeSelections() {
      const selections = [];
      for (const theme of this.themes) {
        selections.push({
          name: theme.name,
          dark: true,
          preview: theme.dark.preview,
        });
      }
      for (const theme of this.themes) {
        selections.push({
          name: theme.name,
          dark: false,
          preview: theme.light.preview,
        });
      }
      return selections;
    },
  },
  methods: {
    selectionIsSelected(selection) {
      return (
        this.$vuetify.theme.themes.name === selection.name &&
        this.$vuetify.theme.dark == selection.dark
      );
    },
    setThemeSelection(selection) {
      this.setTheme(this.themes.find((t) => t.name == selection.name));
      this.setDark(selection.dark);
    },
    setTheme(theme) {
      const name = theme.name;
      const dark = theme.dark;
      const light = theme.light;
      Object.keys(dark).forEach((i) => {
        this.$vuetify.theme.themes.dark[i] = dark[i];
      });
      Object.keys(light).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = light[i];
      });

      this.$vuetify.theme.themes.name = name;

      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

      localStorage.setItem("theme", name);
    },
    setDark(dark) {
      this.$vuetify.theme.dark = dark;
      localStorage.setItem("dark", this.$vuetify.theme.dark ? "dark" : "light");
    },
  },
  created() {
    const initialThemeName = localStorage.getItem("theme") ?? "Standard";
    const initialDark = localStorage.getItem("dark") ?? "light";

    this.setTheme(this.themes.find((t) => t.name == initialThemeName));
    this.setDark(initialDark == "dark" ? true : false);
  },
};
</script>