import { convertIrisToIds, convertListIrisToIds, makeAuthenticatedRequest } from "@/helper/requestHelper"

const activitiesModule = {
    state: {
        teamActivitiesToday: [],
        recentActivities: [],
        yesterdayActivities: [],
        lastFetched: null,
        activityImages: []
    },
    getters: {
        teamActivitiesToday(state) {
            return state.teamActivitiesToday
        },
        personalActivitiesToday(state, _, rootState) {
            return state.teamActivitiesToday.filter(a => a.participant == rootState.client.participantId)
        },
        activityImages(state) {
            return state.activityImages
        },
        recentActivities(state) {
            return state.recentActivities
        },
        activitiesToAdd(state) {
            return state.activitiesToAdd
        },
        yesterdayActivities(state) {
            return state.yesterdayActivities
        },
        needsReload(state) {
            if (!state.lastFetched) return true
            return Date.now() - state.lastFetched > 60000
        }
    },
    mutations: {
        setActivitiesToAdd(state, activitiesToAdd) {
            state.activitiesToAdd = activitiesToAdd
        },
        setRecentActivities(state, activities) {
            state.recentActivities = activities
        },
        setYesterdayActivities(state, activities) {
            state.yesterdayActivities = activities
        },
        setLastFetched(state) {
            state.lastFetched = Date.now()
        },
        clearLastFetched(state) {
            state.lastFetched = null
        },
        setTeamActivitiesToday(state, teamActivitiesToday) {
            state.teamActivitiesToday = teamActivitiesToday
        },
        addToActivityImages(state, activityImage) {
            state.activityImages.push(activityImage)
        },
        replaceActivityImageLikes(state, activityImage) {
            state.activityImages.find(i => i.id == activityImage.id).likes = activityImage.likes
        }
    },
    actions: {
        async createActivity({ dispatch, rootGetters, rootState }, { sport, time, points, image, final }) {
            const response = await makeAuthenticatedRequest("/activities.json", {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                body: JSON.stringify({
                    sport: process.env.VUE_APP_BACKEND_PREFIX + "/sports/" + sport.id,
                    time: time,
                    points: points,
                    day: process.env.VUE_APP_BACKEND_PREFIX + "/days/" + rootGetters["tournament/currentDay"].id,
                    participant: process.env.VUE_APP_BACKEND_PREFIX + "/participants/" + rootState.client.participantId,
                })
            })

            if (image && response.id) {
                await makeAuthenticatedRequest("/activity_images.json", {
                    headers: { "Content-Type": "application/json" },
                    method: "POST",
                    body: JSON.stringify({
                        bytecode: image,
                        activity: process.env.VUE_APP_BACKEND_PREFIX + "/activities/" + response.id
                    })
                })
            }

            if (final) {
                dispatch("loadActivities", { forceReload: true })
                dispatch("tournament/loadDays", { forceReload: true }, { root: true })
                dispatch("tournament/loadWeeks", { forceReload: true }, { root: true })
                await dispatch("participants/loadParticipants", { forceReload: true }, { root: true })
                dispatch("participants/loadTeams", { forceReload: true }, { root: true })
                dispatch("sports/loadSports", { forceReload: true }, { root: true })
            }
        },

        async loadActivities({ rootGetters, getters, commit }, { forceReload }) {
            if (!getters.needsReload && !forceReload) return
            if (!rootGetters['tournament/currentDay'] || !rootGetters["participants/currentTeam"]) return

            const recentActivities = await makeAuthenticatedRequest(
                `/activities.json?day=${rootGetters['tournament/currentDay'].id}&order[beginTime]=desc`)
            commit("setRecentActivities", convertListIrisToIds(recentActivities))

            const yesterdayActivities = await makeAuthenticatedRequest(
                `/activities.json?day=${rootGetters['tournament/currentDay'].id - 1}&order[beginTime]=desc`)
            commit("setYesterdayActivities", convertListIrisToIds(yesterdayActivities))

            const currentTeamParticipants = rootGetters["participants/currentTeam"].members
            const teamActivities = await makeAuthenticatedRequest(
                `/activities.json?day=${rootGetters['tournament/currentDay'].id}&participant[]=${currentTeamParticipants[0]}&participant[]=${currentTeamParticipants[1]}&order[beginTime]=desc`)
            commit("setTeamActivitiesToday", convertListIrisToIds(teamActivities))

            commit("setLastFetched")
        },

        async loadActivityImageForActivity({ getters, commit }, activity) {
            for (const image of getters.activityImages) {
                if (image.activity == activity.id) return
            }

            const newImage = await makeAuthenticatedRequest(
                `/activity_images/${activity.activityImage}.json`)
            commit("addToActivityImages", convertIrisToIds(newImage))
        },

        async likeActivityImage({ commit, rootGetters }, activityImage) {
            let users = []
            for (const userId of activityImage.likes) {
                users.push(process.env.VUE_APP_BACKEND_PREFIX + "/participants/" + userId)
            }
            users.push(process.env.VUE_APP_BACKEND_PREFIX + "/participants/" + rootGetters["participants/currentParticipant"].id)

            const response = await makeAuthenticatedRequest(`/activity_images/${activityImage.id}.json`, {
                headers: { "Content-Type": "application/merge-patch+json" },
                method: "PATCH",
                body: JSON.stringify({
                    likes: users
                })
            })

            commit("replaceActivityImageLikes", convertIrisToIds(response))
        }
    },
    namespaced: true
}

export default activitiesModule