import Vue from 'vue'
import Vuex from 'vuex'
import sportsModule from './sports'
import clientModule from './client'
import participantsModule from './participants'
import tournamentModule from './tournament'
import activitiesModule from './activities'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
  },

  state: {
    globalSnackbar: {
      show: false,
      text: null,
      color: null,
      timeout: -1
    }
  },
  actions: {
    activateErrorMessage({ commit }, payload) {
      payload.color = 'error';
      payload.timeout = payload.timeout ?? 5000;
      payload.text = payload.text ?? "Something went wrong";
      commit('activateSnackbar', payload);
    },
    activateSuccessMessage({ commit }, payload) {
      payload.timeout = payload.timeout ?? 5000;
      payload.text = payload.text ?? "Success!";
      payload.color = 'success';
      commit('activateSnackbar', payload);
    },
    activateInfoMessage({ commit }, payload) {
      payload.timeout = payload.timeout ?? 5000;
      payload.color = 'info';
      commit('activateSnackbar', payload);
    },
    activateSnackbar({ commit }, payload) {
      commit('activateSnackbar', payload);
    },
    resetSnackbar({ commit }) {
      commit('resetSnackbar');
    }
  },
  mutations: {
    activateSnackbar(state, payload) {
      state.globalSnackbar.show = true;
      state.globalSnackbar.text = payload.text;
      state.globalSnackbar.color = payload.color;
      state.globalSnackbar.timeout = payload.timeout || -1;
    },
    resetSnackbar(state) {
      state.globalSnackbar.show = false;
      state.globalSnackbar.text = null;
      state.globalSnackbar.color = null;
    }
  },
  modules: {
    sports: sportsModule,
    client: clientModule,
    participants: participantsModule,
    activities: activitiesModule,
    tournament: tournamentModule
  }
})
