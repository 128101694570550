<template>
  <v-container>
    <v-row align="center" v-for="team in sortedTeams" :key="team.id" no-gutters>
      <v-col cols="2" class="text-right"
        ><span
          :class="'rank-' + (team.weekRanking < 4 ? team.weekRanking : 'any')"
          >{{ team.weekRanking }}.</span
        ></v-col
      >
      <v-col class="text-right" cols="10">
        <team-view
          :maxPoints="winnerTeam?.totalActivityPointsEarned ?? 100"
          :points="team.totalActivityPointsEarned"
          :team="team"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import TeamView from "./TeamView.vue";
export default {
  components: { TeamView },
  computed: {
    ...mapGetters("participants", ["weekTeams"]),
    winnerTeam() {
      return this.weekTeams?.find((t) => t.weekRanking == 1) ?? null;
    },
    sortedTeams() {
      const sortedTeams = this.weekTeams;
      return sortedTeams.sort((a, b) => a.weekRanking > b.weekRanking ? 1 : -1);
    },
  },
};
</script>

<style scoped>
.rank-1,
.rank-2,
.rank-3,
.rank-any {
  position: relative;
  right: 10px;
}
.rank-1 {
  font-size: 3.2em !important;
  color: gold;
  text-shadow: 0 0 10px yellow;
  animation: glow 5s ease 0s infinite;
  opacity: 1;
  text-shadow: 0 0 30px rgb(192, 134, 8), 0 0 10px rgba(255, 255, 255, 0.732),
    0 0 2px black;
}
.rank-2 {
  font-size: 2.6em !important;
  color: #bdc;
  text-shadow: 0 0 10px whitesmoke;
}
.rank-3 {
  font-size: 2.2em !important;
  color: orange;
  text-shadow: 0 0 10px darkgoldenrod;
  padding-bottom: 2em !important;
}
.rank-any {
  font-size: 2em !important;
}

@keyframes glow {
  0% {
    color: gold;
    opacity: 1;
  }
  25% {
    color: yellow;
    opacity: 1;
  }
  50% {
    color: gold;
    opacity: 1;
  }
  75% {
    color: yellow;
    opacity: 1;
  }
  100% {
    color: gold;
    opacity: 1;
  }
}
</style>