<template>
  <v-container class="ma-0 pa-0">
    <v-row no-gutters>
      <v-col>
        <v-file-input
          background-color="background"
          v-model="selectedPicture"
          class="file-input"
          :prepend-icon="null"
          outlined
          accept="image/png, image/jpeg, image/bmp"
          label="Bild auswählen"
          truncate-length="10"
          @change="onFileSelected"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-img
          @click="viewImage = true"
          v-if="selectedByteCode"
          :src="selectedByteCode"
        />
      </v-col>
    </v-row>
    <image-viewer
      :opened="viewImage"
      v-if="selectedByteCode"
      :source="selectedByteCode"
      @close="viewImage = false"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageViewer from "../shared/ImageViewer.vue";
export default {
  components: { ImageViewer },
  name: "activity-image-picker",
  emits: ["select"],
  data() {
    return {
      viewImage: false,
      selectedPicture: null,
      selectedByteCode: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("participants", ["currentParticipant"]),
  },
  methods: {
    ...mapActions("participants", ["updateParticipant"]),
    ...mapActions(["activateSuccessMessage"]),
    async onFileSelected() {
      if (!this.selectedPicture) {
        this.selectedByteCode = null;
        return;
      }
      try {
        const bytecode = await this.calculateBytecode();
        this.selectedByteCode = bytecode;
        this.savePicture();
      } catch (error) {
        console.error(error);
      }
    },
    calculateBytecode() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(this.selectedPicture);
      });
    },
    savePicture() {
      this.$emit("select", this.selectedByteCode);
    },
  },
};
</script>

<style>
.file-input * {
  cursor: pointer;
}
</style>