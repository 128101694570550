<template>
  <v-container class="pa-0 ma-0" v-if="sports && sports.length > 0 && sports[0].badges">
    <v-label
      >Klicke auf das <v-icon small>mdi-information-outline</v-icon> um mehr
      über eine Sportart zu erfahren.</v-label
    >
    <div>
      <v-row align="center" justify="center" class="mt-1">
        <v-col
          cols="4"
          class="text-center py-2 px-1"
          v-for="sport in sports"
          :key="sport.id"
        >
          <base-card
            @click="sportClicked(sport)"
            :class="{
              'pb-1': true,
              sportMaxed: sport.badges.maxed,
            }"
          >
            <div>
              <v-icon
                :class="{
                  'mt-3': true,
                  'mb-1': true,
                  sportLeader: sport.badges.leader,
                }"
                >mdi-{{ sport.icon }}</v-icon
              >
            </div>
            <v-label style="font-size: 0.85em">{{ sport.name }}</v-label>
            <v-btn
              icon
              class="info-button"
              @click.stop="sportToShowDetails = sport"
            >
              <v-icon small> mdi-information-outline </v-icon>
            </v-btn>
          </base-card>
        </v-col>
      </v-row>
    </div>
    <base-dialog
      v-if="sportToShowDetails"
      :open="sportToShowDetails != null"
      :title="sportToShowDetails?.name ?? null"
      :subtitle="
        sportToShowDetails?.description
          ? `inkl. ${sportToShowDetails.description}`
          : null
      "
      @close="sportToShowDetails = null"
    >
      <sport-details :sport="sportToShowDetails" />
    </base-dialog>
  </v-container>
  <v-container v-else
    ><v-row
      ><v-col
        ><v-progress-circular indeterminate :size="20" class="mr-2" />Statistik wird abgerufen...</v-col
      ></v-row
    ></v-container
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseCard from "../BaseCard.vue";
import BaseDialog from "../BaseDialog.vue";
import SportDetails from "../shared/SportDetails.vue";
export default {
  components: { BaseDialog, SportDetails, BaseCard },
  name: "sport-overview",
  emits: ["click"],
  data() {
    return {
      sportToShowDetails: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("sports", ["sports"]),
  },
  async created() {
    this.isLoading = true;
    await this.loadSports({forceReload: true});
    this.isLoading = false
  },
  methods: {
    ...mapActions("sports", ["loadSports"]),
    sportClicked(sport) {
      this.$emit("click", sport);
    },
  },
};
</script>

<style>
.info-button {
  position: absolute;
  top: 0;
  right: 0;
}

.sportLeader {
  text-shadow: 0 0 3px #500 !important;
  color: gold !important;
}

.sportMaxed {
  animation-name: sportShine;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  background: linear-gradient(
    to top right,
    lightyellow,
    transparent,
    transparent,
    transparent,
    transparent
  );
}

@keyframes sportShine {
  0% {
    background: linear-gradient(
      to top right,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent
    );
  }
  2% {
    background: linear-gradient(
      to top right,
      lightyellow,
      transparent,
      transparent,
      transparent,
      transparent
    );
  }
  4% {
    background: linear-gradient(
      to top right,
      transparent,
      lightyellow,
      transparent,
      transparent,
      transparent
    );
  }
  6% {
    background: linear-gradient(
      to top right,
      transparent,
      transparent,
      lightyellow,
      transparent,
      transparent
    );
  }
  8% {
    background: linear-gradient(
      to top right,
      transparent,
      transparent,
      transparent,
      lightyellow,
      transparent
    );
  }
  10% {
    background: linear-gradient(
      to top right,
      transparent,
      transparent,
      transparent,
      transparent,
      lightyellow
    );
  }
  12% {
    background: linear-gradient(
      to top right,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent
    );
  }
  100% {
    background: linear-gradient(
      to top right,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent
    );
  }
}
</style>