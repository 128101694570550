<template>
  <v-container class="pa-0 ma-0" style="margin-bottom: 5em !important" fluid>
    <slot v-if="!loading && !error"> </slot>
    <v-container v-else>
      <v-row justify="center" align="center">
        <v-col class="text-center">
          <div v-if="error" style="position: relative; top: 30vh">
            <v-row>
              <v-col class="text-center"
                ><v-label class="text-h3">Ups!</v-label></v-col
              ></v-row
            >
            <v-row
              ><v-col class="text-center"
                ><v-label>{{ error }}</v-label></v-col
              ></v-row
            >
          </div>
          <v-progress-circular
            v-else
            indeterminate
            style="position: relative; top: 35vh"
            :size="50"
          />
        </v-col>
      </v-row>
    </v-container>
    <the-add-activity-button />
  </v-container>
</template>

<script>
import TheAddActivityButton from "./TheAddActivityButton.vue";
export default {
  components: { TheAddActivityButton },
  name: "base-view",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style>
</style>