<template>
  <v-btn @click="logout" icon><v-icon>mdi-logout</v-icon></v-btn>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "logout-button",
  methods: {
    ...mapActions("client", ["logout"])
  }
};
</script>

<style>
</style>