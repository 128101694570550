<template>
  <base-card solo class="pa-9">
    <v-row>
      <v-col class="text-center">
        <user-icon size="80" :userId="chosenParticipant?.id ?? null" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-text-field
          label="Name"
          v-model="name"
          hide-details
          :loading="checkingName"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-text-field
          @keypress.enter="submit"
          label="Password"
          v-model="password"
          type="password"
          :error-messages="errorMessage"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <v-btn
          @click="submit"
          @keypress.enter="submit"
          :disabled="!formIsValid || isLoading"
          color="primary"
          class="pa-5"
          ><v-icon v-if="!isLoading" class="mr-3">mdi-login</v-icon
          ><v-progress-circular v-else indeterminate class="mr-3" />
          Login</v-btn
        >
      </v-col>
    </v-row>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseCard from "../BaseCard.vue";
import UserIcon from "../shared/UserIcon.vue";
export default {
  components: { UserIcon, BaseCard },
  name: "login-form",
  data() {
    return {
      debouncedInput: "",
      password: "",
      timeout: null,
      checkingName: false,
      isLoading: false,
      errorMessage: null,
    };
  },
  created() {
    if (this.suggestedParticipant) this.name = this.suggestedParticipant.name;
  },
  computed: {
    ...mapGetters("participants", ["participantByName"]),
    ...mapGetters("client", ["suggestedParticipant"]),
    name: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          this.debouncedInput = val;
          await this.checkName();
        }, 400);
      },
    },
    chosenParticipant() {
      return this.participantByName(this.name) ?? null;
    },
    formIsValid() {
      return this.name && this.password;
    },
  },
  methods: {
    ...mapActions("participants", ["loadParticipants"]),
    ...mapActions("client", ["authenticate"]),
    ...mapActions(["activateSuccessMessage"]),
    async checkName() {
      this.checkingName = true;
      await this.loadParticipants({ forceReload: false });
      this.checkingName = false;
    },
    async submit() {
      try {
        this.isLoading = true;
        this.errorMessage = null;
        await this.authenticate({ name: this.name, password: this.password });
        await this.loadParticipants({ forceReload: true });
        this.$router.push({ name: "day" }).catch(() => {});
        this.activateSuccessMessage({
          text: "Willkommen zurück, " + this.name + "!",
          timeout: 2000,
        });
      } catch (ex) {
        this.errorMessage = ex.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>