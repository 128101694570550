<template>
  <v-bottom-navigation
    background-color="background"
    color="primary"
    grow
    app
    v-if="!isLoading"
  >
    <v-btn class="py-7" v-for="tab in activeTabs" :key="tab.text" :to="tab.to">
      <span class="mt-1 text-uppercase">{{ tab.text }}</span>

      <v-icon>{{ tab.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "the-bottom-navigation",
  data() {
    return {
      isLoading: false,
      runningTabs: [
        { text: "Tag", icon: "mdi-calendar", to: "day" },
        { text: "Woche", icon: "mdi-calendar-week", to: "week" },
        { text: "Turnier", icon: "mdi-trophy", to: "tournament" },
        { text: "Profil", icon: "mdi-account", to: "profile" },
      ],
      waitingOrEndedTabs: [
        { text: "Turnier", icon: "mdi-trophy", to: "tournament" },
        { text: "Profil", icon: "mdi-account", to: "profile" },
      ],
    };
  },
  computed: {
    ...mapGetters("tournament", ["tournament", "currentDay", "currentWeek"]),
    activeTabs() {
      if (!this.tournament) return this.waitingOrEndedTabs;
      return this.tournament.status == "running"
        ? this.runningTabs
        : this.waitingOrEndedTabs;
    },
  },
  methods: {
    ...mapActions("tournament", ["loadTournament", "loadWeeks"]),
    ...mapActions("participants", ["loadParticipants"]),
  },
  async created() {
    this.isLoading = true;
    await this.loadParticipants({ forceReload: false });
    await this.loadTournament({ forceReload: false });
    await this.loadWeeks({ forceReload: false });
    if (this.currentDay?.number)
      this.runningTabs.find((t) => t.to == "day").text +=
        " " + this.currentDay.number;
    if (this.currentWeek?.number)
      this.runningTabs.find((t) => t.to == "week").text +=
        " " + this.currentWeek.number;
    this.isLoading = false;
  },
};
</script>

<style>
</style>