<template>
  <div>
    <div
      v-if="isLoading"
      style="
        width: 100vh;
        height: 100vh;
        position: fixed;
        z-index: 90000;
        background: transparent;
        top: 0;
        left: 0;
      "
      opacity="0.5"
    />
    <div>
      <base-dialog
        :open="true"
        @close="close"
        :loading="isLoading"
        :error="error"
        title="Fertig mit Sport?"
        subtitle="Zeit, Punkte zu sammeln!"
      >
        <v-stepper
          v-model="selectedStep"
          elevation="0"
          style="background-color: transparent"
          class="ma-0 pa-0"
        >
          <v-stepper-items class="ma-0 pa-0">
            <v-stepper-content step="1" class="ma-0 pa-0" height="100%">
              <div>
                <v-row justify="center" align="center">
                  <v-col cols="2" class="pr-0 mr-0" style="font-size: 2em"
                    >1.</v-col
                  >
                  <v-col cols="10"
                    >Wähle die Sportarten, die du gemacht hast und klicke
                    <i>Weiter</i>.</v-col
                  >
                  <v-col>
                    <v-alert type="info" text dismissible>
                      Du kannst auch <span class="font-weight-bold">mehrere Sportarten</span> auf einmal wählen!
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row align="center" justify="center" class="mb-1">
                  <v-col
                    cols="3"
                    class="text-center pt-2 pb-1 mb-1 mt-0 px-0 mx-1"
                    v-for="sport in sports"
                    :key="sport.id"
                    :style="
                      sport.totalTimeDoneToday == sport.units[9]
                        ? 'opacity: .5; pointer-events: none;'
                        : ''
                    "
                  >
                    <base-card
                      class="pb-1"
                      @click="sportClicked(sport)"
                      disabled
                      :clickable="true"
                      :color="sportIsSelected(sport) ? 'secondary' : null"
                    >
                      <div>
                        <v-icon class="mt-3 mb-1">mdi-{{ sport.icon }}</v-icon>
                      </div>
                      <v-label style="font-size: 0.85em">{{
                        sport.name
                      }}</v-label>
                      <v-btn
                        small
                        icon
                        class="info-button"
                        @click.stop="sportToShowDetails = sport"
                      >
                        <v-icon small> mdi-information-outline </v-icon>
                      </v-btn>
                    </base-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      :disabled="selectedSports.length == 0"
                      color="primary"
                      @click="selectActivities"
                    >
                      Weiter
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="ma-0 pa-0">
              <div>
                <v-row justify="center" align="center">
                  <v-col cols="2" class="pr-0 mr-0" style="font-size: 2em"
                    >2.</v-col
                  >
                  <v-col
                    >Gib die Zeiten der Sportart(en) an und klicke
                    <i>Weiter</i>.</v-col
                  >
                </v-row>

                <activity-form
                  class="mt-2"
                  v-for="(sport, i) in selectedSports"
                  :key="sport.id"
                  :sportId="sport"
                  v-model="selectedActivities[i]"
                />

                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      class="mt-4"
                      color="primary"
                      @click="confirmActivities"
                    >
                      Weiter
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" class="ma-0 pa-0">
              <div>
                <v-row>
                  <v-col class="text-center">
                    <p>Du erhälst jetzt</p>
                    <p class="font-weight-bold">
                      <points-display :points="totalPoints" />
                    </p>
                    <p>für insgesamt</p>
                    <p class="font-weight-bold">
                      {{ totalTimeString }}
                    </p>
                    <p>Sport.</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <v-btn :loading="isLoading" color="primary" @click="submit"
                      >Cool!</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <base-dialog
          v-if="sportToShowDetails"
          :open="sportToShowDetails != null"
          :title="sportToShowDetails?.name ?? null"
          :subtitle="
            sportToShowDetails?.description
              ? `inkl. ${sportToShowDetails.description}`
              : null
          "
          @close="sportToShowDetails = null"
        >
          <sport-details :sport="sportToShowDetails" />
        </base-dialog>
      </base-dialog>
    </div>
  </div>
</template>

<script>
import { toHoursAndMinutesString } from "@/helper/displayHelper";
import { mapActions, mapGetters, mapMutations } from "vuex";
import BaseCard from "../BaseCard.vue";
import BaseDialog from "../BaseDialog.vue";
import PointsDisplay from "../shared/PointsDisplay.vue";
import SportDetails from "../shared/SportDetails.vue";
import ActivityForm from "./ActivityForm.vue";
export default {
  name: "add-activities",
  emits: ["close"],
  components: {
    BaseDialog,
    ActivityForm,
    SportDetails,
    BaseCard,
    PointsDisplay,
  },
  async created() {
    try {
      this.isLoading = true;
      this.error = null;
      await this.loadSports({ forceReload: true });
    } catch (ex) {
      this.error = ex.message;
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      isLoading: true,
      error: null,
      selectedSports: [],
      selectedActivities: [],
      sportToShowDetails: null,
      selectedStep: 1,
    };
  },
  computed: {
    ...mapGetters("activities", ["activitiesToAdd"]),
    ...mapGetters("sports", ["sports"]),
    ...mapGetters("participants", ["currentParticipant"]),
    totalPoints() {
      let sum = 0;
      for (const activity of this.selectedActivities) {
        sum += activity?.points ? activity.points : 0;
      }
      return sum;
    },
    totalTime() {
      let sum = 0;
      for (const activity of this.selectedActivities) {
        sum += activity?.time ? activity.time : 0;
      }
      return sum;
    },
    totalTimeString() {
      return `${toHoursAndMinutesString(this.totalTime)} h`;
    },
    allActivitiesSelected() {
      if (this.selectedActivities.length == 0) return false;
      for (const activity of this.selectedActivities) {
        if (!activity?.time) return false;
        if (activity.time == 0) return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations("activities", ["setActivitiesToAdd"]),
    ...mapActions("sports", ["loadSports"]),
    ...mapActions("activities", ["createActivity"]),
    ...mapActions(["activateSuccessMessage"]),
    selectActivities() {
      this.selectedStep = 2;
    },
    confirmActivities() {
      this.selectedStep = 3;
    },
    close() {
      this.$emit("close");
    },
    sportClicked(sport) {
      if (this.selectedSports.includes(sport.id)) {
        this.selectedSports = this.selectedSports.filter((s) => s != sport.id);
        this.selectedActivities = this.selectedActivities.filter(
          (s) => s.sport.id != sport.id
        );
      } else {
        this.selectedSports.push(sport.id);
      }
    },
    sportIsSelected(sport) {
      return this.selectedSports.includes(sport.id);
    },

    async submit() {
      try {
        this.isLoading = true;
        let i = 0;
        for (const sport of this.selectedSports) {
          let final = i == this.selectedActivities.length - 1;
          i++;
          let activity = this.selectedActivities.find(
            (a) => a.sport.id == sport
          );
          if (!activity?.time) continue;
          await this.createActivity({
            sport: activity.sport,
            time: activity.time,
            points: activity.points,
            image: activity.image,
            final: final,
          });
        }
        this.$emit("close");
        var audio = null;
        if (
          this.currentParticipant.name == "Jonas" ||
          this.currentParticipant.name == "Nils"
        ) {
          audio = new Audio(require("@/assets/fart.mp3"));
          audio.volume = 1;
        } else {
          audio = new Audio(require("@/assets/success.mp3"));
          audio.volume = 0.4;
        }
        audio.play();
        this.activateSuccessMessage({
          text: "Deine Aktivitäten wurden eingetragen",
          timeout: 3000,
        });
      } catch (ex) {
        console.error(ex);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
