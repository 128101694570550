<template>
  <base-view :loading="isLoading" :error="error">
    <day-progress-banner />
    <base-section
      title="Teamaktivitäten"
      initiallyOpened
      style="margin-top: 0.5em !important"
    >
      <team-day-activity :team="currentTeam" />
    </base-section>
    <base-section title="Heutige Aktivitäten" initiallyOpened
      ><template #header>
        <v-spacer /><v-col cols="2"
          ><v-btn icon @click.stop="update" :disabled="isUpdating">
            <v-icon v-if="!isUpdating">mdi-reload</v-icon
            ><v-progress-circular indeterminate v-else /></v-btn></v-col
      ></template>
      <div :class="{ loading: isUpdating }">
        <activity-list
          :activities="recentActivities"
          emptyString="Heute hat noch niemand Sport gemacht."
          :disabled="isUpdating"
        /></div
    ></base-section>

    <base-section title="Gestrige Aktivitäten">
      <activity-list
        :activities="yesterdayActivities"
        emptyString="Gestern hat niemand Sport gemacht."
    /></base-section>
  </base-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActivityList from "../components/activities/ActivityList.vue";
import BaseSection from "../components/BaseSection.vue";
import BaseView from "../components/BaseView.vue";
import DayProgressBanner from "../components/shared/DayProgressBanner.vue";
import TeamDayActivity from "../components/teams/TeamDayActivity.vue";
export default {
  components: {
    DayProgressBanner,
    BaseView,
    BaseSection,
    ActivityList,
    TeamDayActivity,
  },
  name: "current-day-view",
  data() {
    return {
      isLoading: true,
      isUpdating: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters("activities", ["recentActivities", "yesterdayActivities"]),
    ...mapGetters("participants", ["currentTeam"]),
  },
  methods: {
    ...mapActions("activities", ["loadActivities"]),
    ...mapActions("sports", ["loadSports"]),
    ...mapActions("participants", ["loadParticipants", "loadTeams"]),
    ...mapActions("tournament", ["loadWeeks", "loadTournament", "loadDays"]),
    async update() {
      try {
        this.isUpdating = true;
        await this.loadActivities({ forceReload: true });
        this.loadParticipants({ forceReload: true });
      } catch (ex) {
        this.error = ex.message;
        console.error(ex);
      } finally {
        this.isUpdating = false;
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.all([
        this.loadSports({ forceReload: false, unauthenticated: true }),
        this.loadParticipants({ forceReload: false }),
        this.loadTournament({ forceReload: false }),
        this.loadWeeks({ forceReload: false }),
        this.loadDays({ forceReload: false }),
        this.loadTeams({ forceReload: false }),
      ]);
      await this.loadActivities({ forceReload: false });
    } catch (ex) {
      this.error = ex.message;
      console.error(ex);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style>
.loading {
  opacity: 0.5;
  pointer-events: none;
}
</style>