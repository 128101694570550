<template>
  <v-container>
    <v-row align="center">
      <v-col cols="6"
        ><v-label
          v-if="tournament"
          class="text-overline"
          style="font-size: 0.8em !important; line-height: 3px"
        >
          {{ tournament.name }}
        </v-label></v-col
      ><v-spacer /><v-col class="text-right mr-0 pr-0" cols="6"
        ><v-btn icon @click="showingInfo = true"
          ><v-icon>mdi-information-outline</v-icon></v-btn
        >
        <theme-changer /><logout-button v-if="isAuthenticated"
      /></v-col>
    </v-row>
    <base-dialog :open="showingInfo" @close="showingInfo = false">
      <info-card v-if="showingInfo" />
    </base-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import BaseDialog from "./BaseDialog.vue";
import LogoutButton from "./login/LogoutButton.vue";
import InfoCard from "./shared/InfoCard.vue";
import ThemeChanger from "./shared/ThemeChanger.vue";
export default {
  components: { LogoutButton, ThemeChanger, InfoCard, BaseDialog },
  name: "the-app-bar",
  data() {
    return {
      isLoading: false,
      showingInfo: false,
    };
  },
  computed: {
    ...mapGetters("client", ["isAuthenticated", "participantId"]),
    ...mapGetters("participants", ["participantById"]),
    ...mapState("tournament", ["tournament"]),
    ...mapState("participants", ["teams"]),
    participant() {
      if (!this.isAuthenticated) return;
      return this.participantById(this.participantId);
    },
  },
  methods: {
    ...mapActions("participants", ["loadTeams"]),
    ...mapActions("tournament", ["loadWeeks", "loadTournament"]),
  },
};
</script>

<style>
</style>