<template>
  <v-dialog :value="opened" @click:outside="close">
    <div v-if="source">
      <v-img :src="source" @click="view = !view" style="max-height: 80vh">
        <v-btn
          @click="close"
          style="
            position: absolute;
            right: 1em;
            top: 1em;
            background-color: rgba(255, 255, 255, 0.5);
          "
          icon
          ><v-icon color="black">mdi-close</v-icon></v-btn
        >
        <v-container v-if="!view && hasLikes">
          <v-row style="position: absolute; bottom: 0.5em">
            <v-col cols="3">
              <span>
                <v-btn fap @click.stop="like">
                  <v-icon :color="iconColor" class="mr-1">mdi-{{ icon }}</v-icon
                  ><label style="font-size: 1.3em">{{
                    likes.length
                  }}</label> </v-btn
                ><v-btn
                  class="px-0 mt-1"
                  style="pointer-events: none; min-width: 5.5em"
                  v-if="likes && likes.length > 0"
                  ><span v-for="(like, i) in likes" :key="i">
                    <user-icon
                      v-if="i < likeTreshold"
                      size="28"
                      :userId="like"
                      :style="`position: relative; right: ${10 * (i - 1)}px`"
                    />
                  </span>
                  <label
                    v-if="likes.length > likeTreshold"
                    style="position: relative; right: 1.5em; font-size: 1.2em"
                    >+{{ likes.length - likeTreshold }}</label
                  ></v-btn
                >
              </span></v-col
            ></v-row
          ></v-container
        ></v-img
      >
    </div>
    <v-card v-else>
      <v-container align="center" justify="center">
        <v-row>
          <v-col class="text-center my-10">
            <v-progress-circular indeterminate class="mb-2" /><br />
            Lade Bild...
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserIcon from "./UserIcon.vue";
export default {
  components: { UserIcon },
  emits: ["close", "like"],
  name: "image-viewer",
  props: {
    source: {
      type: String,
      required: false,
    },
    opened: {
      type: Boolean,
      required: true,
    },
    likes: {
      type: Array,
      required: false,
    },
    ownerUser: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLiking: false,
      likeTreshold: 6,
      view: false,
    };
  },
  computed: {
    ...mapGetters("participants", ["currentParticipant"]),
    hasLikes() {
      return this.likes != null;
    },
    isLiked() {
      return this.likes && this.likes.includes(this.currentParticipant.id);
    },
    isLikable() {
      if (this.ownerUser && this.ownerUser.id == this.currentParticipant.id)
        return false;
      return !this.isLiked;
    },
    icon() {
      return this.isLiked || this.isLiking ? "heart" : "heart-outline";
    },
    iconColor() {
      return this.isLiked || this.isLiking ? "red" : "default";
    },
  },
  methods: {
    ...mapActions("activities", ["likeActivityImage"]),
    ...mapActions(["activateInfoMessage"]),
    close() {
      this.$emit("close");
    },
    like() {
      if (this.ownerUser && this.ownerUser.id == this.currentParticipant.id) {
        this.activateInfoMessage({
          text: "Du willst dein eigenes Bild liken? Cringe.",
          timeout: 2000,
        });
      }
      if (!this.isLikable) return;
      this.isLiking = true;
      this.$emit("like");
    },
  },
};
</script>

<style>
</style>