<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <div v-if="!isLoading">
      <v-app-bar app color="background">
        <the-app-bar />
      </v-app-bar>

      <v-main>
        <v-container class="pa-0 ma-0" fluid>
          <router-view>
            <v-progress-linear height="5" indeterminate />
          </router-view>
        </v-container>
      </v-main>

      <the-bottom-navigation v-if="isAuthenticated" />
    </div>
    <v-progress-linear indeterminate v-else />
    <the-global-snackbar />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TheAppBar from "./components/TheAppBar.vue";
import TheBottomNavigation from "./components/TheBottomNavigation.vue";
import TheGlobalSnackbar from "./components/TheGlobalSnackbar.vue";
export default {
  components: { TheAppBar, TheBottomNavigation, TheGlobalSnackbar },
  name: "App",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("client", ["isAuthenticated"]),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    color() {
      return this.$vuetify.theme.themes[this.theme].background;
    },
  },
  methods: {
    ...mapActions(["initializeUnauthenticated"]),
  },
};
</script>