<template>
  <base-card id="day-progress-banner">
    <v-card-title class="pb-1">
      <v-col class="pa-0 ma-0">Tag {{ currentDay.number }} </v-col><v-spacer /><v-col
        class="pa-0 ma-0 text-right"
        ><points-display
          :points="teamAcitivtyPoints"
          style="position: absolute; right: 1em"
      /></v-col>
    </v-card-title>
    <v-card-text>
      <v-col class="pa-0 ma-0"
        ><v-label id="time-left" class="mr-2">{{
          timeLeftString
        }}</v-label></v-col
      >
    </v-card-text>
    <v-progress-linear height="5" :value="relativeTimePassedToday">
    </v-progress-linear>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseCard from '../BaseCard.vue';
import PointsDisplay from "./PointsDisplay.vue";
export default {
  components: { PointsDisplay, BaseCard },
  name: "day-progress-banner",
  created() {
    this.timeUntilNextDay = (new Date().setHours(24, 0, 0, 0) - Date.now() - this.serverDifference) / 1000;
    window.setInterval(this.runTimer, 1000);
  },
  data() {
    return {
      timeUntilNextDay: null
    }
  },
  computed: {
    ...mapGetters("tournament", ["currentDay", "serverDifference"]),
    ...mapGetters("activities", ["teamActivitiesToday"]),
    ...mapGetters("sports", ["sportById"]),
    ...mapGetters("client", ["participantId"]),
    teamAcitivtyPoints() {
      return this.teamActivitiesToday.reduce(
        (sum, a) => sum + a.points,
        0
      );
    },
    timeLeftString() {
      const hoursLeft = Math.floor(this.timeUntilNextDay / 3600);
      const minutesLeft = Math.floor(
        (this.timeUntilNextDay - 3600 * hoursLeft) / 60
      );
      const secondsLeft = Math.floor(
        this.timeUntilNextDay - 3600 * hoursLeft - 60 * minutesLeft
      );
      return `endet in ${String(hoursLeft).padStart(2, "0")}:${String(
        minutesLeft
      ).padStart(2, "0")}:${String(secondsLeft).padStart(2, "0")}`;
    },
    relativeTimePassedToday() {
      const now = new Date();
      return ((now.getHours() * 60 + now.getMinutes()) / (24 * 60)) * 100;
    },
  },
  methods: {
    ...mapActions("tournament", ["loadCurrentDay"]),
    runTimer() {
      this.timeUntilNextDay -= 1;
      if (this.timeUntilNextDay <= 0) {
        window.parent.location.reload();
      }
    },
  },
};
</script>

<style>
#day-progress-banner {
  position: sticky;
  left: 0;
  width: 100vw !important;
  border-radius: 0;
  z-index: 100;
}
</style>