<template>
  <v-select
    background-color="background"
    :items="availableTournamentsForRegistration"
    label="Turnier"
    :item-text="(t) => t.name"
    :item-value="(t) => t"
    v-model="tournament"
    @input="selectTournamentInStore"
    no-data-text="Keine Turniere gefunden."
    class="mb-1"
  >
  </v-select>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "tournament-select",
  data() {
    return {
      tournament: null,
    };
  },
  computed: {
    ...mapGetters("tournament", [
      "availableTournamentsForRegistration",
      "selectedTournament",
    ]),
  },
  methods: {
    ...mapActions("tournament", ["setSelectedTournament"]),
    selectTournamentInStore() {
      this.setSelectedTournament(this.tournament);
    },
  },
  created() {
    this.tournament = this.selectedTournament;
  },
};
</script>