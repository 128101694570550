import store from "@/store"

export async function makeAuthenticatedRequest(url, options) {
    const reqOptions = { ...options, headers: options?.headers ?? {} }
    reqOptions.headers = {
        ...reqOptions.headers,
        "Authorization": "Bearer " + store.state.client.token,
        "Accept": "application/json"
    }
    const data = await fetch(process.env.VUE_APP_BACKEND_URL + url, reqOptions)
    const response = await data.json()
    if (response.code == 401) {
        store.dispatch("client/logout")
        throw new Error()
    }
    return response
}

export function convertListIrisToIds(entities) {
    const entitiesWithIds = []
    for (const entity of entities) {
        entitiesWithIds.push(convertIrisToIds(entity))
    }
    return entitiesWithIds
}

export function convertIrisToIds(entity) {
    const entityWithIds = {}
    for (const [key, value] of Object.entries(entity)) {
        if (!Array.isArray(value) && (typeof value === 'object' || !value.toString().startsWith("/"))) {
            entityWithIds[key] = value;
            continue
        }
        else if (!Array.isArray(value)) {
            entityWithIds[key] = parseInt(value.split("/")[value.split("/").length - 1])
        }
        else {
            const propertyWithIds = []
            for (const entry of value) {
                if (!value.toString().startsWith("/")) { propertyWithIds.push(entry) }
                else {
                    propertyWithIds.push(parseInt(entry.split("/")[entry.split("/").length - 1]))
                }
            }
            entityWithIds[key] = propertyWithIds
        }
    }
    return entityWithIds
}