<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="3" class="text-right"
        ><v-icon x-large>mdi-{{ sport.icon }}</v-icon></v-col
      ><v-col
        ><div>
          <v-icon small class="mr-1" style="bottom: 2px"
            >mdi-information-outline</v-icon
          >Info:
        </div>
        {{
          sport.definition && sport.definition.length > 0
            ? sport.definition
            : sport.name
        }}
      </v-col>
    </v-row>
    <v-divider class="mt-5 mb-7" />
    <v-row align="center" justify="center">
      <v-col cols="6" v-for="(unit, i) in sport.units" :key="unit">
        <v-icon class="mr-1">mdi-numeric-{{ i + 1 }}-circle</v-icon
        ><v-label style="top: 2px" class="ml-1"
          >{{ toHoursAndMinutesString(unit) }} h</v-label
        >
      </v-col>
    </v-row>
    <v-divider class="mt-5 mb-7" />
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-icon small class="mr-1">{{
          sport.badges.maxed ? "mdi-check-circle" : "mdi-information"
        }}</v-icon
        ><v-label style="font-size: .8em" class="ml-1">{{
          sport.badges.maxed
            ? "Du hast ein schillerndes Abzeichen für diese Sportart gesammelt."
            : "Sammle an einem einzgen Tag 10 Punkte durch " +
              sport.name +
              " um dir ein schillerndes Abzeichen zu verdienen."
        }}</v-label>
      </v-col>
      <v-col cols="12" class="mt-0 pt-0">
        <v-icon small class="mr-1">{{
          sport.badges.leader ? "mdi-check-circle" : "mdi-information"
        }}</v-icon
        ><v-label style="font-size: .8em" class="ml-1">{{
          sport.badges.leader
            ? "Du besitzt momentan das goldene Abzeichen für diese Sportart. Verliere es nicht!"
            : "Sammle mehr Aktivitätspunkte in " +
              sport.name +
              " als alle anderen Teilnehmer um dir das goldene Abzeichen zu verdienen."
        }}</v-label>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toHoursAndMinutesString } from "@/helper/displayHelper";
export default {
  name: "sport-details",
  props: {
    sport: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toHoursAndMinutesString(minutes) {
      return toHoursAndMinutesString(minutes);
    },
  },
};
</script>

<style>
</style>