<template>
  <base-card id="day-progress-banner">
    <v-card-title class="pb-1">
      <v-col class="pa-0 ma-0">Woche {{ currentWeek.number }} </v-col
      ><v-spacer /><v-col class="pa-0 ma-0 text-right"
        ><points-display
          :points="teamAcitivtyPoints"
          style="position: absolute; right: 1em"
      /></v-col>
    </v-card-title>
    <v-card-text>
      <v-col class="pa-0 ma-0"
        ><v-label id="time-left" class="mr-2">{{
          timeLeftString
        }}</v-label></v-col
      >
    </v-card-text>
    <v-progress-linear height="5" :value="relativeTimePassedThisWeek">
    </v-progress-linear>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseCard from "../BaseCard.vue";
import PointsDisplay from "./PointsDisplay.vue";
export default {
  components: { PointsDisplay, BaseCard },
  name: "week-progress-banner",
  created() {
    this.timeUntilNextDay =
      (new Date().setHours(24, 0, 0, 0) - Date.now() - this.serverDifference) /
      1000;
    window.setInterval(this.runTimer, 1000);
  },
  data() {
    return {
      timeUntilNextDay: null,
    };
  },
  computed: {
    ...mapGetters("tournament", [
      "currentDay",
      "currentWeek",
      "serverDifference",
    ]),
    ...mapGetters("activities", ["teamActivitiesToday"]),
    ...mapGetters("sports", ["sportById"]),
    ...mapGetters("client", ["participantId"]),
    ...mapGetters("participants", ["currentTeam"]),
    teamAcitivtyPoints() {
      return this.currentTeam?.totalActivityPointsEarned ?? 0;
    },
    timeLeftString() {
      return this.daysUntilNextWeek > 1
        ? `endet in ${this.daysUntilNextWeek} Tagen`
        : `endet ${this.daysUntilNextWeek == 0 ? "heute" : "morgen"} Nacht`;
    },
    relativeTimePassedToday() {
      const now = new Date();
      return ((now.getHours() * 60 + now.getMinutes()) / (24 * 60)) * 100;
    },
    relativeTimePassedThisWeek() {
      return (
        ((6 - this.daysUntilNextWeek) / 7) * 100 +
        this.relativeTimePassedToday / 7
      );
    },
    daysUntilNextWeek() {
      return this.currentDay.number % 7 == 0
        ? 0
        : 7 - (this.currentDay.number % 7);
    },
  },
  methods: {
    ...mapActions("tournament", ["loadCurrentDay"]),
    runTimer() {
      this.timeUntilNextDay -= 1;
      if (this.timeUntilNextDay <= 0) {
        window.parent.location.reload();
      }
    },
  },
};
</script>

<style>
#day-progress-banner {
  position: sticky;
  top: 3.5em;
  left: 0;
  width: 100vw !important;
  border-radius: 0;
  z-index: 100;
}

#time-left {
}
</style>