<template>
  <v-expansion-panels v-model="opened">
    <v-expansion-panel>
      <v-expansion-panel-header color="background"
        ><span class="text-overline">
          Woche {{ week.number }}
        </span></v-expansion-panel-header
      >
      <v-expansion-panel-content color="background" class="section-panel">
        <v-row>
          <v-col>
            <v-label v-if="week.number < currentWeek?.number">Platzierung deines Teams: {{participantTeam.weekRanking}}. Platz</v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            v-for="teamId in week.teams"
            :key="teamId"
            class="mb-2"
          >
            <user-icon
              size="35"
              :userId="teamById(teamId).members[0]"
              clickable
            />
            <user-icon
              clickable
              style="position: relative; right: 0.5em"
              size="35"
              :userId="teamById(teamId).members[1]"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from "vuex";
import UserIcon from "../shared/UserIcon.vue";
export default {
  components: { UserIcon },
  name: "week-view",
  data() {
    return {
      opened: false,
    };
  },
  props: {
    week: {
      required: true,
      type: Object,
    },
    initiallyOpened: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("participants", ["teamById", "currentParticipant"]),
    ...mapGetters("tournament", ["days", "currentWeek"]),
    participantTeam() {
      let participantTeam = this.week.teams.find(t => this.teamById(t).members[0] == this.currentParticipant.id)
      return this.teamById(participantTeam)
    }
  },
  created() {
    if (this.initiallyOpened) this.opened = 0;
  },
  methods: {
    getBeginDate(week) {
      const firstDay = this.days.find((d) => d.id == week.days[0]);
      return new Date(firstDay?.date).toLocaleDateString("de-DE") ?? null;
    },
  },
};
</script>

<style>
</style>