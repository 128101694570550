<template>
  <v-snackbar
    v-model="globalSnackbar.show"
    :color="globalSnackbar.color"
    location="bottom"
    app
    :timeout="globalSnackbar.timeout"
  >
    {{ globalSnackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "the-global-snackbar",
  methods: {
    close() {
      this.$store.dispatch("resetSnackbar");
    },
  },
  computed: {
    ...mapState(["globalSnackbar"]),
  },
};
</script>
