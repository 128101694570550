import { convertIrisToIds, convertListIrisToIds, makeAuthenticatedRequest } from "@/helper/requestHelper"

const tournamentModule = {
    state: {
        tournament: null,
        days: [],
        allDays: [],
        weeks: [],
        daysLastFetched: null,
        weeksLastFetched: null,
        tournamentLastFetched: null,
        selectedTournamentId: null,
        availableTournaments: [],
        serverDifference: 0
    },
    getters: {
        days(state) {
            return state.days
        },
        allDays(state) {
            return state.allDays
        },
        weeks(state) {
            return state.weeks
        },
        tournament(state) {
            if (!state.tournament) return null
            const tournament = state.tournament
            tournament.beginDate = new Date(tournament.beginDate)
            tournament.endDate = new Date(tournament.endDate)
            tournament.serverTime = new Date(tournament.serverTime)
            return tournament
        },
        availableTournaments(state) {
            return state.availableTournaments
        },
        availableTournamentsForRegistration(state) {
            return state.availableTournaments.filter(t => t.status == "waiting")
        },
        selectedTournament(state) {
            if (!state.selectedTournamentId) return null
            return state.availableTournaments.find(t => t.id == state.selectedTournamentId)
        },
        currentDay(state) {
            return state.tournament?.currentDay ?? null
        },
        currentDayTimeString(_, getters) {
            return getters.currentDay?.toString() ?? null
        },
        currentWeek(_, getters) {
            if (!getters.currentDay) return null
            return getters.weeks.find(w => w.number == Math.ceil(getters.currentDay.number / 7))
        },
        serverDifference(state) {
            return state.serverDifference
        },
        daysNeedReload(state) {
            return Date.now() - state.daysLastFetched > 60000
        },
        weeksNeedReload(state) {
            return Date.now() - state.weeksLastFetched > 60000
        },
        tournamentNeedsReload(state) {
            return Date.now() - state.tournamentLastFetched > 60000
        },

    },
    mutations: {
        setAvailableTournaments(state, tournaments) {
            state.availableTournaments = tournaments
        },
        setDays(state, days) {
            state.days = days
        },
        setAllDays(state, allDays) {
            state.allDays = allDays
        },
        setWeeks(state, weeks) {
            state.weeks = weeks
        },
        setTournament(state, tournament) {
            state.tournament = tournament
        },
        setSelectedTournamentId(state, tournamentId) {
            state.selectedTournamentId = tournamentId
            localStorage.setItem('selectedTournament', tournamentId)
        },
        setDaysLastFetched(state) {
            state.daysLastFetched = Date.now()
        },
        setWeeksLastFetched(state) {
            state.weeksLastFetched = Date.now()
        },
        setTournamentLastFetched(state) {
            state.tournamentLastFetched = Date.now()
        },
        clearLastFetched(state) {
            state.tournamentLastFetched = null
            state.weeksLastFetched = null
            state.daysLastFetched = null
        },
        setServerDifference(state, serverTime) {
            state.serverDifference = Date.now() - serverTime
        }
    },
    actions: {
        setSelectedTournament({ commit }, tournament) {
            if (!tournament && !localStorage.getItem('selectedTournament')) return
            commit('setSelectedTournamentId', tournament?.id ?? localStorage.getItem('selectedTournament'))
        },

        async loadTournaments({ commit }) {
            const response = await fetch(process.env.VUE_APP_BACKEND_URL + "/tournaments.json",
                { headers: { "Accept": "application/json" } })
            const data = await response.json()
            commit("setAvailableTournaments", convertListIrisToIds(data))
        },

        async loadTournament({ commit, getters, rootGetters }, { forceReload }) {
            const tournamentId = rootGetters["participants/currentParticipant"]?.tournament ?? null
            if ((!getters.tournamentNeedsReload && !forceReload) || !tournamentId) return

            const response = await fetch(process.env.VUE_APP_BACKEND_URL + "/tournaments/" + tournamentId,
                { headers: { "Accept": "application/json" } })
            const data = await response.json()
            commit("setTournament", convertIrisToIds(data))

            commit("setServerDifference", new Date(data.serverTime))

            commit("setTournamentLastFetched")
        },

        async loadWeeks({ commit, getters }, { forceReload }) {
            if (!getters.weeksNeedReload && !forceReload) return

            if (!getters.tournament) return

            const data = await makeAuthenticatedRequest("/weeks?tournament=" + getters.tournament.id,
                { headers: { "Accept": "application/json" } })
            commit("setWeeks", convertListIrisToIds(data))
            commit("setWeeksLastFetched")
        },

        async loadDays({ commit, getters }, { forceReload }) {
            if (!getters.daysNeedReload && !forceReload) return

            if (!getters.currentWeek) {
                commit("setDays", [])
                commit("setDaysLastFetched")
                return
            }

            // let data = await makeAuthenticatedRequest("/days.json",
            //     { headers: { "Accept": "application/json" } })
            // commit("setAllDays", convertListIrisToIds(data))

            let data = await makeAuthenticatedRequest("/days?week=" + getters.currentWeek.id,
                { headers: { "Accept": "application/json" } })
            commit("setDays", convertListIrisToIds(data))
            commit("setDaysLastFetched")
        },
    },
    namespaced: true
}

export default tournamentModule