<template>
  <base-card solo class="pa-9">
    <p class="text-left pb-0 mb-0" v-if="selectedStep > 1">
      <v-btn icon @click="selectedStep--"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
    </p>
    <v-stepper
      v-model="selectedStep"
      elevation="0"
      style="background-color: transparent"
    >
      <v-stepper-items>
        <v-stepper-content step="1" class="ma-0 pa-0">
          <v-card-title class="ml-0 pl-0">Willkommen!</v-card-title>
          <v-row>
            <v-col class="text-left">
              <p>
                <v-label
                  >Schön, dass du mitmachen möchtest! Damit du später deine
                  Punkte eintragen und Ergebnisse einsehen kannst, musst du dich
                  registrieren.</v-label
                >
              </p>
              <p>
                <v-label
                  >Alle weiteren Infos und Regeln erhälst du im
                  Nachhinein.</v-label
                >
              </p>
              <p class="text-right">
                <v-btn
                  color="secondary"
                  class="mt-4 mr-2"
                  @click="selectedStep = 2"
                  >Cool!</v-btn
                >
              </p>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2" class="ma-0 pa-0">
          <div v-if="availableTournamentsForRegistration.length > 0">
            <v-card-title class="ml-0 pl-0">Wo nimmst du teil?</v-card-title>
            <p class="text-left">
              <v-label
                >Bitte wähle das Turnier, für das du dich registrieren
                möchtest.</v-label
              >
            </p>
            <v-row>
              <v-col class="text-center">
                <tournament-select />
              </v-col>
            </v-row>
            <p class="text-right">
              <v-btn
                color="secondary"
                class="mt-4 mr-2 mb-1"
                @click="selectedStep = 3"
                :disabled="!selectedTournament"
                >Weiter</v-btn
              >
            </p>
          </div>
          <div v-else-if="loadingTournaments">
            <v-progress-circular indeterminate />
          </div>
          <div v-else>
            <v-card-title class="ml-0 pl-0">Keine Turniere!</v-card-title>
            <p class="text-left">
              <v-label
                >Derzeit gibt es leider keine Turniere, für die du dich anmelden
                kannst.</v-label
              >
            </p>
            <p class="text-left">
              <v-label>Schau später nochmal vorbei.</v-label>
            </p>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3" class="ma-0 pa-0">
          <v-card-title class="ml-0 pl-0">Wie heißt du?</v-card-title>
          <p class="text-left">
            <v-label
              >Wähle einen Namen. Dein Name dient zur Anzeige und zur
              Anmeldung.</v-label
            >
          </p>
          <v-row>
            <v-col class="text-center">
              <v-text-field
                :append-icon="nameAvailable ? 'mdi-check' : null"
                label="Name"
                v-model="name"
                :loading="checkingName"
                :color="nameAvailable ? 'green' : 'default'"
                @input="nameAvailable = null"
                :error-messages="
                  nameAvailable == false
                    ? 'Dieser Name ist leider schon vergeben'
                    : ''
                "
                :hint="
                  name.length < 3
                    ? 'Dein Name muss min. 3 Zeichen enthalten.'
                    : null
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <p class="text-right">
            <v-btn
              color="secondary"
              class="mt-4 mr-2 mb-1"
              @click="selectedStep = 4"
              :disabled="!nameAvailable"
              >Passt so!</v-btn
            >
          </p>
        </v-stepper-content>
        <v-stepper-content step="4" class="ma-0 pa-0"
          ><v-card-title class="ml-0 pl-0">Passwort?</v-card-title>
          <p class="text-left">
            <v-label
              >Bitte wähle ein Passwort. Dieses Passwort kann auch von anderen
              Teilnehmern genutzt werden, um Punkte in deinem Namen
              einzutragen.</v-label
            >
          </p>
          <p class="text-left">
            <v-label>Bitte merke dir dein Passwort.</v-label>
          </p>
          <v-row>
            <v-col class="text-center mb-0 pb-0">
              <v-text-field
                @input="
                  passwordVerify = '';
                  passwordsMatch = false;
                "
                :append-icon="password.length > 3 ? 'mdi-check' : null"
                :color="password.length > 3 ? 'green' : 'default'"
                label="Passwort"
                v-model="password"
                type="password"
                :hint="
                  password.length < 4
                    ? 'Das Passwort muss min. 4 Zeichen enthalten.'
                    : null
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-text-field
                :append-icon="passwordsMatch ? 'mdi-check' : null"
                :color="passwordsMatch ? 'green' : 'default'"
                @input="checkPasswords"
                label="Passwort wiederholen"
                v-model="passwordVerify"
                type="password"
                :error-messages="
                  passwordsMatch == false
                    ? 'Passwörter stimmen nicht überein.'
                    : ''
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <p class="text-right">
            <v-btn
              color="secondary"
              class="mt-4 mr-2 mb-1"
              @click="selectedStep = 5"
              :disabled="!passwordsMatch || password.length < 4"
              >Fertig!</v-btn
            >
          </p>
        </v-stepper-content>

        <v-stepper-content step="5" v-if="name && selectedTournament">
          <v-card-title class="ml-0 pl-0">Stimmt alles?</v-card-title>
          <p class="text-left">
            <v-label
              >Du meldest dich jetzt als
              <span class="font-weight-bold">
                {{ name }}
              </span>
              für das Turnier
              <span class="font-weight-bold">
                {{ selectedTournament.name }}
              </span>
              an.
            </v-label>
          </p>
          <p class="text-center">
            <v-btn
              color="primary"
              class="mt-2"
              @click="submit"
              :loading="isLoading"
              >Stimmt so!</v-btn
            >
          </p>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <welcome-user v-if="registrationDone" :password="password" :name="name" />
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseCard from "../BaseCard.vue";
import TournamentSelect from "./TournamentSelect.vue";
import WelcomeUser from "./WelcomeUser.vue";
export default {
  components: { TournamentSelect, WelcomeUser, BaseCard },
  name: "register-form",
  data() {
    return {
      debouncedInput: "",
      password: "",
      passwordVerify: "",
      passwordsMatch: null,
      timeout: null,
      checkingName: false,
      isLoading: false,
      errorMessage: null,
      selectedStep: 1,
      nameAvailable: null,
      registrationDone: false,
      loadingTournaments: false,
    };
  },
  computed: {
    ...mapGetters("participants", ["participantByName"]),
    ...mapGetters("tournament", [
      "selectedTournament",
      "availableTournamentsForRegistration",
    ]),
    name: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          this.debouncedInput = val;
          if (this.debouncedInput.length < 3) return;
          await this.checkName();
        }, 400);
      },
    },
    formIsValid() {
      return this.name && this.password;
    },
  },
  async created() {
    this.loadingTournaments = true;
    try {
      await this.loadTournaments();
    } catch (ex) {
      console.error(ex);
    } finally {
      this.loadingTournaments = false;
    }
  },
  methods: {
    ...mapActions("participants", ["loadParticipants"]),
    ...mapActions("client", ["register"]),
    ...mapActions("tournament", ["loadTournaments"]),
    async checkName() {
      this.checkingName = true;
      await this.loadParticipants({ forceReload: true });
      this.nameAvailable = this.participantByName(this.name) == null;
      this.checkingName = false;
    },
    checkPasswords() {
      if (!(this.password && this.passwordVerify)) this.passwordsMatch = null;
      this.passwordsMatch = this.password == this.passwordVerify;
    },
    async submit() {
      try {
        this.isLoading = true;
        await this.register({ name: this.name, password: this.password });
        this.registrationDone = true;
      } catch (ex) {
        this.errorMessage = ex.message;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>