<template>
  <div>
    <v-expansion-panels
      flat
      accordion
      style="left: 0"
      v-model="opened"
      class="mb-2"
    >
      <v-expansion-panel>
        <v-expansion-panel-header color="background"
          ><v-col><span v-if="title" style="font-size: 1.4em">
            {{ title }} </span
          ></v-col><slot name="header"> </slot
        ></v-expansion-panel-header>
        <v-expansion-panel-content class="section-panel" color="background"
          ><slot> kein Inhalt </slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "base-section",
  data() {
    return {
      opened: -1,
    };
  },
  props: {
    initiallyOpened: {
      default: false,
      required: false,
      type: Boolean,
    },
    title: {
      required: false,
      type: String,
    },
  },
  created() {
    this.opened = this.initiallyOpened ? 0 : -1;
  },
};
</script>

<style>
.section-panel {
  background-color: transparent !important;
}

.section-divider {
  width: 96vw;
  position: relative;
  left: 2vw;
}
</style>