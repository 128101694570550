<template>
  <span
    ><span style="font-size: 2em" class="mr-2 ml-1">{{ points }}</span
    ><span style="font-size: 0.9em">Pkt.</span></span
  >
</template>

<script>
export default {
  name: "points-display",
  props: {
    points: {
      type: Number,
      required: true,
      deafault: 0,
    },
  },
};
</script>

<style>
</style>