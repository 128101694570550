<template>
  <v-container v-if="sports[0] && sports[0].badges">
    <v-row class="mb-4">
      <v-col class="text-center">
        <v-label>Deine Sportzeiten</v-label>
        <v-sparkline
          :value="activityPoints"
          auto-draw
          :radius="5"
          smooth
          auto-line-width
          :show-labels="true"
          height="150"
          :labels="labels"
          label-size="10"
          padding="20"
        >
        </v-sparkline>
      </v-col>
    </v-row>
    <v-row v-for="sport in sortedSports" :key="sport.id" align="center">
      <v-col cols="6" class="text-right"
        ><span>{{ sport.name }}</span>
        <v-icon class="ml-2">mdi-{{ sport.icon }}</v-icon>
      </v-col>
      <v-col cols="6">
        <v-label>{{ timeString(sport.totalTimeDone) }} h</v-label>
        <v-progress-linear
          :value="(sport.totalTimeDone / sortedSports[0].totalTimeDone) * 100"
        />
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else
    ><v-row
      ><v-col
        ><v-progress-circular indeterminate :size="20" class="mr-2" />Statistik
        wird abgerufen...</v-col
      ></v-row
    ></v-container
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { toHoursAndMinutesString } from "@/helper/displayHelper";
export default {
  name: "sport-stats",
  async created() {
    if (this.sports[0] && this.sports[0].badges) return
    await this.loadSports({ forceReload: true });
  },
  computed: {
    ...mapGetters("tournament", ["allDays"]),
    ...mapGetters("sports", ["sports"]),
    activityPoints() {
      return this.allDays.map((d) => d.ownTime);
    },
    sortedSports() {
      let sortedSports = this.sports;
      return sortedSports.slice().sort((a, b) =>
        a.totalTimeDone < b.totalTimeDone ? 1 : -1
      );
    },
    labels() {
      return this.allDays.map((d) =>
        d.number != 1 &&
        d.number != this.allDays.length &&
        d.number != Math.ceil(this.allDays.length / 4) &&
        d.number != Math.ceil(this.allDays.length / 2) &&
        d.number != Math.ceil((this.allDays.length / 4) * 3)
          ? " "
          : "Tag " + d.number
      );
    },
  },
  methods: {
    ...mapActions("sports", ["loadSports"]),
    timeString(minutes) {
      return toHoursAndMinutesString(minutes);
    },
  },
};
</script>

<style>
</style>