<template>
  <v-form>
    <base-card class="ma-2 pa-3">
      <v-container>
        <v-row class="pt-0 mt-0">
          <v-col cols="12" class="pt-0 mt-0">
            <div class="text-h6">{{ selectedSport.name }}</div>
          </v-col></v-row
        >
        <v-row class="pt-0 mt-0">
          <v-col cols="2">
            <v-icon>mdi-clock-outline</v-icon>
          </v-col>
          <v-col>
            <v-label
              ><span style="font-size: 0.8em">heute übrig:</span
              ><span style="font-size: 1em">
                {{ totalTimeString }}</span
              ></v-label
            >
          </v-col>
        </v-row>
        <v-row class="pt-0 my-5" align="center" no-gutters>
          <v-col>
            <activity-time-picker
              v-if="totalTimeAvailable != 0"
              :sport="selectedSport"
              :available="totalTimeAvailable"
              @input="setTimeDone"
            />
            <v-row v-else
              ><v-col cols="2"><v-icon>mdi-arm-flex</v-icon></v-col
              ><v-col cols="10"
                ><v-label
                  >Du hast in dieser Sportart heute bereits die Maximalpunktzahl
                  erreicht.</v-label
                ></v-col
              ></v-row
            >
          </v-col>
        </v-row>
        <v-row v-if="totalTimeAvailable != 0" class="pt-0 mt-0" align="center">
          <v-col cols="6" class="text-right">
            <v-icon>mdi-trophy</v-icon>
          </v-col>
          <v-col cols="6" class="text-left">
            <span style="font-size: 2em">{{ pointsToGain }}</span>
          </v-col>
          <v-col class="mt-0 pt-0 text-center">
            <v-progress-linear
              class="mb-2"
              :value="relativeTimeAfterActivity"
              :buffer-value="relativeTimeForNextUnit"
              stream
            >
            </v-progress-linear>
            <v-label
              style="font-size: 0.8em"
              v-if="relativeTimeAfterActivity != 100"
              >Noch
              <b
                >{{ totalTimeForNextUnit }} Minute{{
                  totalTimeForNextUnit == 1 ? "" : "n"
                }}</b
              >
              bis zum nächsten Punkt.</v-label
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-expansion-panels flat accordion class="ma-0 pa-0">
              <v-expansion-panel class="section ma-0 pa-0">
                <v-expansion-panel-header
                  class="section text-left ml-0 pl-0"
                  elevation="0"
                  ><v-icon class="mr-1"
                    >mdi-{{ selectedImage ? "check" : "camera" }}</v-icon
                  ><v-label>{{
                    selectedImage ? "Bild hinzugefügt" : "Bild hinzufügen"
                  }}</v-label></v-expansion-panel-header
                >
                <v-expansion-panel-content
                  ><activity-image-picker @select="selectImage"
                /></v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </base-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { toHoursAndMinutesString } from "@/helper/displayHelper";
import ActivityTimePicker from "./ActivityTimePicker.vue";
import BaseCard from "../BaseCard.vue";
import ActivityImagePicker from "./ActivityImagePicker.vue";
export default {
  components: { ActivityTimePicker, BaseCard, ActivityImagePicker },
  name: "activity-form",
  props: {
    sportId: {
      type: Number,
      required: true,
    },
  },
  emits: ["input"],
  data() {
    return {
      expandPictureSelect: false,
      selectedStep: 1,
      selectedUnits: null,
      selectedTime: 1,
      selectedImage: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("sports", ["sports"]),
    ...mapGetters("activities", ["personalActivitiesToday"]),
    selectedSport() {
      return this.sports.find((s) => s.id == this.sportId);
    },
    totalTimeInSelectedSportToday() {
      return (
        this.sports.find((s) => s.id == this.sportId).totalTimeDoneToday ?? 0
      );
    },
    maxTimeInSelectedSport() {
      return this.selectedSport.maxTime;
    },
    relativeTimeUsed() {
      return (
        (this.totalTimeInSelectedSportToday * 100) / this.maxTimeInSelectedSport
      );
    },
    totalTimeAvailable() {
      return this.maxTimeInSelectedSport - this.totalTimeInSelectedSportToday;
    },
    totalTimeString() {
      return `${toHoursAndMinutesString(this.totalTimeAvailable)} h`;
    },
    relativeTimeAfterActivity() {
      return (this.timeAfterActivity / this.maxTimeInSelectedSport) * 100;
    },
    timeAfterActivity() {
      return this.totalTimeInSelectedSportToday + this.selectedTime;
    },
    totalTimeForNextUnit() {
      if (this.maxTimeInSelectedSport == this.timeAfterActivity) return 0;
      const nextUnit = this.selectedSport.units.find(
        (u) => u > this.timeAfterActivity
      );
      return nextUnit - this.timeAfterActivity;
    },
    relativeTimeForNextUnit() {
      if (this.maxTimeInSelectedSport == this.timeAfterActivity) return 100;
      const nextUnit = this.selectedSport.units.find(
        (u) => u > this.timeAfterActivity
      );
      return (nextUnit / this.maxTimeInSelectedSport) * 100;
    },
    pointsToGain() {
      let sum = 0;
      for (const unit of this.selectedSport.units) {
        if (
          unit > this.totalTimeInSelectedSportToday &&
          unit <= this.timeAfterActivity
        ) {
          sum++;
        }
      }
      return sum;
    },
    totalTimeGainedString() {
      return `${toHoursAndMinutesString(this.selectedTime)} h`;
    },
  },
  methods: {
    ...mapActions("activities", ["createActivity"]),
    ...mapActions(["activateSuccessMessage"]),
    selectImage(image) {
      this.selectedImage = image;
      this.updateActivity();
    },
    updateActivity() {
      this.$emit("input", {
        sport: this.selectedSport,
        time: this.selectedTime,
        points: this.pointsToGain,
        image: this.selectedImage,
      });
    },
    setTimeDone(time) {
      this.selectedTime = time;
      this.updateActivity();
    },
  },
};
</script>

<style>
.section {
  background-color: transparent !important;
}
</style>