<template>
  <v-container class="pt-0 mt-0">
    <v-row no-gutters>
      <v-col>
        <v-label class="text-overline"
          >Score von {{ participant.name }}:
          <b
            ><span style="font-size: 1.5em" class="ml-1">{{
              achievement.value
            }}</span></b
          ></v-label
        ></v-col
      >
    </v-row>

    <v-row align="center">
      <v-col cols="2"
        ><v-icon color="#950">mdi-{{ achievement.icon }}</v-icon></v-col
      ><v-col cols="6">
        <v-progress-linear
          color="#950"
          :value="(achievement.value / achievement.values[0]) * 100"
        />
      </v-col>
      <v-col cols="4">
        <v-icon v-if="achievementLevel > 0">mdi-check</v-icon>
        <v-label v-else
          >{{ achievement.value }} / {{ achievement.values[0] }}</v-label
        >
      </v-col>

      <v-col cols="2"
        ><v-icon color="#bdc">mdi-{{ achievement.icon }}</v-icon></v-col
      ><v-col cols="6">
        <v-progress-linear
          color="#bdc"
          :value="(achievement.value / achievement.values[1]) * 100"
        />
      </v-col>
      <v-col cols="4">
        <v-icon v-if="achievementLevel > 1">mdi-check</v-icon>
        <v-label v-else
          >{{ achievement.value }} / {{ achievement.values[1] }}</v-label
        >
      </v-col>

      <v-col cols="2"
        ><v-icon color="#fc1">mdi-{{ achievement.icon }}</v-icon></v-col
      ><v-col cols="6">
        <v-progress-linear
          color="#fc1"
          :value="(achievement.value / achievement.values[2]) * 100"
        />
      </v-col>
      <v-col cols="4">
        <v-icon v-if="achievementLevel > 2">mdi-check</v-icon>
        <v-label v-else
          >{{ achievement.value }} / {{ achievement.values[2] }}</v-label
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "badge-details",
  props: {
    achievement: {
      type: Object,
      required: true,
    },
    participant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    achievementLevel() {
      if (this.achievement.values.length == 1) {
        return this.achievement.value < this.achievement.values[0] ? 0 : 3;
      }
      if (this.achievement.value < this.achievement.values[0]) {
        return 0;
      }
      if (this.achievement.value < this.achievement.values[1]) {
        return 1;
      }
      if (this.achievement.value < this.achievement.values[2]) {
        return 2;
      }
      return 3;
    },
  },
};
</script>

<style>
</style>