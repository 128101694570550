<template>
  <div>
    <v-btn
      v-if="currentDay"
      id="add-activity-button"
      color="primary"
      fab
      :elevation="2"
      @click="addingActivities = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <add-activities v-if="addingActivities" @close="addingActivities = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddActivities from "./activities/AddActivities.vue";
export default {
  components: { AddActivities },
  name: "the-add-activity-button",
  data() {
    return {
      addingActivities: false,
    };
  },
  computed: {
    ...mapGetters("tournament", ["currentDay"]),
  },
};
</script>

<style>
#add-activity-button {
  position: fixed;
  bottom: 5em;
  right: 2em;
  z-index: 150;
}
</style>