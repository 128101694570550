import Vue from 'vue'
import VueRouter from 'vue-router'
import CurrentDayView from '@/views/CurrentDayView.vue'
import CurrentWeekView from '@/views/CurrentWeekView.vue'
import TournamentView from '@/views/TournamentView.vue'
import ProfileView from '@/views/ProfileView.vue'
import LoginView from '@/views/LoginView.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/day',
    name: 'day',
    component: CurrentDayView,
    alias: '/',
    meta: {
      requiresPublic: false,
      requiresAuthentication: true
    }
  },
  {
    path: '/week',
    name: 'week',
    component: CurrentWeekView,
    meta: {
      requiresPublic: false,
      requiresAuthentication: true
    }
  },
  {
    path: '/tournament',
    name: 'tournament',
    component: TournamentView,
    meta: {
      requiresPublic: false,
      requiresAuthentication: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      requiresPublic: false,
      requiresAuthentication: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresPublic: true,
      requiresAuthentication: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: LoginView,
    meta: {
      requiresPublic: true,
      requiresAuthentication: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!from.name) {
    await store.dispatch("client/initializeUnauthenticated")
  }
  const isAuthenticated = store.getters["client/isAuthenticated"]
  if (to.meta.requiresAuthentication) {
    if (!isAuthenticated) {
      next({ name: "login" })
    }
  } else if (to.meta.requiresPublic) {
    if (isAuthenticated) {
      next({ name: "day" })
    }
  }

  if (to.name == "day") {

    await store.dispatch("participants/loadParticipants", { forceReload: false }, { root: true });
    await store.dispatch("tournament/loadTournament", { forceReload: false }, { root: true });
    await store.dispatch("tournament/loadWeeks", { forceReload: false }, { root: true });
    await store.dispatch("tournament/loadDays", { forceReload: false }, { root: true });

    if (!store.getters["tournament/currentDay"]) {
      next({ name: "tournament" })
    }
  }
  next(true)
})

export default router
