<template>
  <v-container class="pa-0 ma-0">
    <v-row align="center" justify="center" class="pa-0 ma-0">
      <v-col cols="2" class="text-right pa-0 ma-0">
        <user-icon
          clickable
          size="40"
          style="position: relative; left: 1em; z-index: 10"
          :userId="team.members[0]"
        />
      </v-col>
      <v-col cols="8" class="pa-0 ma-0">
        <v-progress-linear
          :value="relativeParticipation"
          height="40"
          color="rgba(150,150,150,.4)"
          background-color="rgba(200,200,200,.4)"
        >
          <v-row>
            <v-col class="text-left ml-6">{{ ownPoints }}</v-col
            ><v-spacer /><v-col class="text-right mr-6">{{
              teammatePoints
            }}</v-col>
          </v-row>
        </v-progress-linear>
      </v-col>
      <v-col cols="2" class="text-left ma-0 pa-0">
        <user-icon
          clickable
          size="40"
          style="position: relative; right: 1em; z-index: 10"
          :userId="team.members[1]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserIcon from "../shared/UserIcon.vue";
export default {
  components: { UserIcon },
  name: "team-participant-comparison",
  props: {
    team: {
      type: Object,
      required: false,
    },
    teamActivities: {
      type: Array,
      required: false,
    },
    ownTotalPoints: {
      type: Number,
      required: false,
      default: 0,
    },
    teamTotalPoints: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("sports", ["sportById"]),
    ...mapGetters("client", ["participantId"]),
    teamPoints() {
      if (!this.teamActivities) return this.teamTotalPoints;
      return this.teamActivities.reduce((sum, a) => sum + a.points, 0);
    },
    ownPoints() {
      if (!this.teamActivities) return this.ownTotalPoints;
      return this.teamActivities
        .filter((a) => a.participant == this.participantId)
        .reduce((sum, a) => sum + a.points, 0);
    },
    teammatePoints() {
      return this.teamPoints - this.ownPoints;
    },
    relativeParticipation() {
      const rawValue = (this.ownPoints / this.teamPoints) * 100;
      const finalValue = Math.max(Math.min(100, rawValue), 0);
      return finalValue;
    },
  },
};
</script>